export default {
  set (state, userStrategies) {
    state.userStrategies = userStrategies
  },
  setComparisonIds (state, comparisonIds) {
    state.comparisonIds = comparisonIds
  },
  setValidationProperties (state, validationProperties) {
    state.validationProperties = validationProperties
  }
}
