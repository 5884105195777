<template>

</template>

<script>
import {mapGetters} from 'vuex'
export default {
  mounted() {
	  // let isAdmin = JSON.parse(localStorage.getItem(process.env.VUE_APP_LOCAL_STORAGE_IS_ADMIN_LABEL))
	  this.$auth.logout()
	  window.location.href = "https://www.solentsis.com/"

	  // window.open("https://www.solentsis.com");

	  // if(isAdmin) {
		 //  window.location.href = process.env.VUE_APP_ADMIN_URL
	  // }else {
	  //   window.location.reload()
	  // }
  }
}
</script>
