<template>
    <div>
        <div class="container container-custom pb-5">
            <div class="container container-relative">
                <div class="row justify-content-center">
                  <button title="Capture Result" class="btn btn-primary pull-right refresh-button refresh-button-compare capture-icon " @click="download"> <i class="fas fa-camera"></i></button>

                  <div class="col-md-12 pb-5" id="print-image">
                        <div class="card mt-3em-b-1-w">
                            <div class=" heading-color card-custom2">Comparison Result</div>
                        <div class="card-body">
                            <div class="row">
                                <div class="col-sm-12">
                                    <apexchart width="100%" height="507" type="line"
                                               :options="options" :series="series"></apexchart>
                                </div>
                            </div>
                            <div class="row mt-4">
                                  <p class="text-center card-header heading-color-internel performance-stats-class w-100">Performance Statistics</p>
                                <table class="table table-bordered">
                                    <thead class="comparison-table text-center">
                                        <th>Strategy Name</th>
                                        <th>Currency</th>
                                        <th>Annualised Return</th>
                                        <th>Annualised Volatility</th>
                                        <th>Sharp Ratio</th>
                                        <th>Beta to US Equities</th>
                                        <th>Maximum Drawdown</th>
                                        <th>Best Month</th>
                                        <th>Worst Month</th>
                                    </thead>
                                    <tbody class="text-center">
                                      <tr v-for="userStrategy in userStrategies">

                                            <td v-text="userStrategy.name"></td>
                                            <td v-text="userStrategy.currency"></td>
                                            <td>{{userStrategy.performanceStats.annualisedReturn ? userStrategy.performanceStats.annualisedReturn.toFixed(2) : 0}}%</td>
                                            <td>{{userStrategy.performanceStats.annualisedVolatility ? userStrategy.performanceStats.annualisedVolatility.toFixed(2) : 0}}%</td>
                                            <td v-text="userStrategy.performanceStats.sharpRatio ? userStrategy.performanceStats.sharpRatio.toFixed(2) : 0"></td>
                                            <td v-text="userStrategy.performanceStats.betaToUsEquity ? userStrategy.performanceStats.betaToUsEquity.toFixed(2) : 0"></td>
                                            <td>
                                                <span v-if="userStrategy.performanceStats.maxDrawdown">
                                                    {{userStrategy.performanceStats.maxDrawdown ? userStrategy.performanceStats.maxDrawdown.toFixed(2) : 0}}%
                                                </span>
                                            </td>
                                            <td>{{userStrategy.performanceStats.bestMonth ? userStrategy.performanceStats.bestMonth.toFixed(2) : 0}}%</td>
                                            <td >{{userStrategy.performanceStats.worstMonth ? userStrategy.performanceStats.worstMonth.toFixed(2) : 0}}%</td>
                                      </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        </div>
    </div>
</template>

<script>
import _ from 'lodash'
import { mapGetters } from 'vuex'
import moment from 'moment'
import DomToImage from 'dom-to-image'
import { saveAs } from 'file-saver'

export default {
	name: 'comparison-result',
  data: function() {
    return {
	    isLoader: false,
	    userStrategies: []
    }
  },
	computed: {
		series () {
			let chartSeries = []
			let strategySeries = []
			if(this.userStrategies) {
				this.userStrategies.forEach(userStrategy => {
          strategySeries = _.map(userStrategy.chartData, (val, index) => {
					return {
						x: moment(val[ 'returnMonth' ], 'MM-YYYY'),
						y: val[ 'cumulativeValue' ].toFixed(0)
					}
				})
          chartSeries.push({
	                           name: userStrategy.name,
	                           data: strategySeries
                           })
				})
			}
			return chartSeries

		},
		options () {
			return {
				chart: {
					id: 'strategies-comparison-chart',
					toolbar: {
						show: true,
						tools: {
							download: false,
							selection: true,
							zoom: true,
							zoomin: true,
							zoomout: true,
							pan: true,
							customIcons: []
						},
						autoSelected: 'zoom'
					},
				},
				xaxis: {
					type: 'datetime',
						categories: this.returnMonths,
					labels: {
						showDuplicates: false,
						// format: 'MM/yy',
						formatter: function (val, timestamp) {
							return moment(timestamp).format('MMM-YYYY')
						},
						datetimeFormatter: {
							year: 'yy',
							month: 'MMM \'yy',
						},
						min: this.minDate,
						max: this.maxDate
					},
					dataLabels: {
						enabled: true,
						formatter: function (val, opts) {
							return val
						},
						textAnchor: 'middle',
						offsetX: 0,
						offsetY: 0,
						style: {
							fontSize: '14px',
							fontFamily: 'Helvetica, Arial, sans-serif',
							colors: undefined
						},
					}
				},
				yaxis: {
					show: true,
					showAlways: true,
					title: {
						text: 'Cumulative Portfolio Result',
						rotate: 90,
						offsetX: 0,
						offsetY: 0,
						style: {
							color: '#00aced',
							fontSize: '15px',
							// fontFamily: 'Helvetica, Arial, sans-serif',
							// cssClass: 'apexcharts-yaxis-title',
						},
					},
				}
			}
		},
		userStrategy () {
			return  this.userStrategies[0]
		},
    returnMonths () {
			if(this.userStrategy) {
				return _.map(this.userStrategy.chartData, chart => {
					return moment(chart[ 'returnMonth' ], 'MM-YYYY')
				})
			}
    },
    minDate () {
	    let strategy = _.minBy(this.userStrategies, 'fromDate')
      if(strategy){
	    	return moment(strategy.fromDate, 'MM-YYYY')
      }
    },
    maxDate () {
	    let strategy = _.minBy(this.userStrategies, 'toDate')
	    if(strategy){
		    return moment(strategy.toDate, 'MM-YYYY')
	    }
    },
    performanceStats () {
			return this.userStrategy ? this.userStrategy.performanceStats : {}
		},

	},
	methods: {
		sessionValidate () {
			if(!this.$auth.isAuthenticated()){
				this.$router.push('/login')
			}
		},
		validateComparisonIds() {
			if(this.userStrategies.length === 0){
				this.$toastr.e('No strategy selected for comparison.', 'Error')
				this.$router.push('/comparison/strategies')
			}else if(this.userStrategies.length < 2){
				this.$toastr.e('Please select two or more strategies for comparison.', 'Error')
				this.$router.push('/comparison/strategies')
			}
		},
		async fetchSetups () {
			if (! this.userStrategies) {
				await this.$store.dispatch('UserStrategy/fetch')
			}
      this.userStrategies = this.$store.getters['UserStrategy/comparisonSelected']
		},
		download() {
			let node = document.getElementById('print-image')
			let options = {
				quality: 1.0
			}
			DomToImage.toBlob(node, options).then(function (blob) {
				saveAs(blob, 'compare-result.png')
			})
		}

	},
  async mounted () {
	  this.sessionValidate()
	  await this.fetchSetups()
	  this.validateComparisonIds()
	  this.isLoader = false
	}
}

</script>

<style scoped>
  /*.card{flex-direction: initial;}*/
  .mt-5em{margin-top: 5em}
  .card-custom{    box-shadow: 0px 0px 8px 0px;}
  .pb-4em{padding-bottom: 4em}
  .mt-3em-b-1-w{
    margin-top: 1em;
    border-top: 1px solid white;
    }
  .card-custom2{
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
    width: 30%;
    background-color: #2c3e50;
    border-bottom: 3px solid #00aced;
      /*margin-top: 15px;*/
    padding: 11px;
    text-align: center;
    font-weight: bold;
    }
    .result-table>tbody>tr{
        background-color: #FEF1DE  ;
        }
    @media screen and (max-width: 340px){
        .performance-table{
            padding-left: 0;
            }
        }
    .comparison-table{
        background-color: #133042;
        color: white;
        font-weight: 400;
        }
  .comparison-table th{

        font-weight: 400;
        }
    .heading-color-internel{
        margin-right:1px;
        margin-left: 1px;
        background-color: #FE9400;
        }
  .capture-icon{
    font-size: 20px;
    }
</style>

