<template>
	<div>
		<div v-if="" class=" solent-spinner-row ">
			<circle2  class="solent-spinner "></circle2>
		</div>

		<h1>Client Panel</h1>
		<h1>Token: </h1>
		<h3 v-text="token"> </h3>
		<h1>Expired At: </h1>
		<h3 v-text="expiredAt"> </h3>
		<h1> End </h1>
	</div>
</template>

<script>
import {Boolean} from '../enums/Boolean'
export default {
	name: 'customer-panel',
	data() {
		return {
			isLoader: true,
			token: this.$route.query.token,
			expiredAt: this.$route.query.expiredAt
		}
	},
	mounted() {
  	if(this.token) {
		  this.$auth.setSession({
			                        token: this.token,
			                        expiredAt: this.expiredAt,
			                        type: Boolean.YES
		                        })
		  this.$router.push('/user-strategies')
	  }else{
		  this.$router.push('/login')
	  }
  }
}
</script>
