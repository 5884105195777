import '@babel/polyfill'
import 'mutationobserver-shim'
import Vue from 'vue'
// ‎Vue.use(localStorage)
// import './plugins/font-awesom'
// import './plugins/vue2-datatable'
import './plugins/bootstrap-vue'
import './plugins/axios'
import './plugins/date-picker'
import './plugins/vue-select'
import './plugins/apexchart'
import './plugins/vue-loader-spinner'
import './plugins/vue-toastr'
import './plugins/vuelidate'
// import './plugins/VueMonthPicker'
import '../src/css/solent-styles.scss'
import Auth from '../src/services/auth'
import AuthLayout from './components/AuthLayout.vue'
import GuestLayout from './components/GuestLayout.vue'

Vue.component('auth-layout', AuthLayout)
Vue.component('guest-layout', GuestLayout)

import App from './App.vue'
import store from './store'
import router from './router'
Vue.use(Auth)
Vue.config.productionTip = false

new Vue({
  store,
  router,
  render: h => h(App)
}).$mount('#app')
