<template >
	<div class="">
		<header class="primary-header header-custom">
			<div id="head" class="container">
				<div class="topnav" id="myTopnav">
				<div class="row">
					<div class="col-md-4 ">
						<div class="logo">
							<a href="/" style="text-decoration: none;"><img src="https://www.solentsis.net/wp-content/themes/solentsis/images/logo.png"
							                 title="INVESTMENT STRATEGY BUILDER" alt="INVESTMENT STRATEGY BUILDER">
								<p class="text-solent">INVESTMENT STRATEGY BUILDER</p>
							</a>
						</div>
					</div>

					<top-menu-bar-guest v-if="!isAuth"></top-menu-bar-guest>
					<top-menu-bar-authenticated v-if="isAuth"></top-menu-bar-authenticated>
					<a href="javascript:void(0);" style="background: none" class="icon responsive-icon item-menu-link" @click="myFunction()">
						<i class="fa fa-bars"></i>
					</a>
					</div>
				</div>
			</div>
		</header>
	</div >
</template >
<script >
	import { mapGetters } from 'vuex'
    import TopMenuBarAuthenticated from '@/components/TopMenuBarAuthenticated.vue'
    import TopMenuBarGuest from '@/components/TopMenuBarGuest.vue'
	export default {
		name: 'header-menu',
		props: ['isAuth'],
    components: {TopMenuBarAuthenticated, TopMenuBarGuest },
        methods: {
            myFunction() {
                var x = document.getElementById("myTopnav");
                if (x.className === "topnav") {
                    x.className += " responsive";
                } else {
                    x.className = "topnav";
                }
            }
        }
	}
</script >
<style scoped >
	.nav {
		padding         : 30px;
		justify-content : center;
		}
	.nav a {
		font-weight : bold;
		color       : #2c3e50;
		}
	.nav a.router-link-exact-active {
		color : #42b983;
		}
	.text-solent{
		font-size: 11px;
		color: white;
	}
</style >
