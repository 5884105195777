import getters from './getters'
import mutations from './mutations'
import actions from './actions'

export const Auth = {
  namespaced: true,
  state: {
    user: {},
    isAuthenticated: false,
    expiredAt: 0
  },
  getters,
  mutations,
  actions
};
