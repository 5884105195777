import axios from 'axios'
import common from '../common'
const GET_STRATEGIES_URL = 'markets';

export default {
    fetch: function ({ commit }) {
		  return axios.get(GET_STRATEGIES_URL).then(res => {
			  commit('set', common.prepareThen(res).data)
		  }).catch(error => {
			  return common.prepareCatch(error)
		  })
  }
}
