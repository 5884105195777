<template >
	<div class="col-md-8 ">
		<div class="row">
			<div class="col-md-12 mt-3em ">
					<router-link to = "/user-strategies/create" class = "item-menu-link hovering-colr  text-decoration-none float-left responsive" > Create</router-link >
					<router-link to = "/user-strategies" class = "item-menu-link  hovering-colr text-decoration-none color-white responsive" > Your Strategies</router-link >
					<router-link to = "/comparison/strategies" class = "item-menu-link  hovering-colr text-decoration-none color-white responsive" > Compare </router-link >
					<router-link to = "/logout" class = "item-menu-link hovering-colr text-decoration-none pb-10  float-right-logout responsive" > Logout
						<i class="fas fa-sign-out-alt" title="Logout"></i></router-link >
			</div>
		</div>
	</div>
</template >
<script >
	import { mapGetters } from 'vuex'
	export default {
		name: 'top-menu-bar',
		data () {
			return {
				isAdminLogin : false
			}
		},
		computed: {
			isAuthenticated() {
				return this.$auth.isAuthenticated()
			}
		},
		mounted() {
			this.isAdminLogin =  this.$auth.isAdminLogin()
		}
	}
	function reloadPage(){
		location.reload(true);
	}
</script >
<style scoped >
	.item-menu{width: 50%;float: left}
	.p-12{padding: 12px}
	.m-20{margin: 20px}
	.hovering-colr:hover{color: #59B6DB}
	.nav {
		padding         : 30px;
		justify-content : center;
		}
	.nav a {
		font-weight : bold;
		color       : #2c3e50;
		}
	.nav a.router-link-exact-active {
		color : #42b983;
		}
</style >
