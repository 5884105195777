<template>

  <div>
    <h2 class="text-center login-class pb-2 rounded-sm">Reset Password</h2>
    <div class="card custom-login-card ">
      <div class="card-body ">

<!--          <div class="form-group ">-->
<!--            <label for="password">Email Adress</label>-->
<!--            <input id="password" type="email" class="form-control" name="email" v-model="email" autocomplete="email" autofocus>-->
<!--          </div>-->
          <div class="form-group ">
            <label >New Password</label>
            <input type="password" class="form-control" v-model="password" autocomplete="password" autofocus>
          </div>
          <div class="form-group ">
            <label>Confirm Password</label>
            <input type="password" class="form-control" v-model="confirm_password" autocomplete="password_confirmation" autofocus>
          </div>

          <div class="reg-btn">
            <button type="submit" class="btn btn-primary rounded-pill login-button"  @click="submit">Reset Password</button>
          </div>
      </div>
    </div>
  </div>


</template>
<script >
	import {mapGetters} from 'vuex'
	export default {
		name: 'reset-password-confirmation',
		data () {
			return {
				token: this.$route.params.token,
				password: '',
				confirm_password: '',
			}
		},
		components: {
		},
		computed: {
		},
		methods: {
			submit () {
				// this.isShow = false
				this.$store.dispatch('Auth/passwordReset', {
					token: this.token,
          password: this.password,
					password_confirmation: this.confirm_password,
				}).then(res => {
					if(res.status === 200) {
						this.$toastr.s('Your new password updated successfully.', 'Success')
						this.$router.push('/login')
					}else {
						this.isShow = true
						this.$toastr.e('Something went wrong please try again.', 'Error')
					}
				}).catch(err => {
					this.isShow = true
					if(err.response.status === 422){
						// _.forEach(err.response.data.message.errors.password, error => {
							this.$toastr.e(err.response.data.errors.password[0], 'Error')
						// })
					}else if(err.response.status === 406) {
						this.$toastr.e(err.response.data.message, 'Error')
					}else {
						this.$toastr.e('Something went wrong please try again.', 'Error')
					}
				})
			}
		},
		mounted () {
		},
	}
</script >
<style scoped>
  /*.card{flex-direction: initial;}*/

  /*.mb-22-1em{margin-bottom: 22.1em}*/
.card-header{
  background-color: #F99800;
  color: white;

  }
  .login-class{
    background-color: #F99800;
    color: #ffffff;
    padding: 3px;
    margin-bottom: 5%;
    }
  .custom-login-card .card-body{
    background-color: #132E41;
    padding: 7%;
    color: white;
    }
  .register-button{
    background-color: #FC9301;
    color: white;
    text-align: center;
    padding-left: 7%;
    padding-right: 7%;
    align-content: center;
    }
  .login-button{
    text-align: center;
    padding-left: 7%;
    padding-right: 7%;
    }
  .reg-btn{
    text-align: center;
    }
  @media screen and (max-width: 560px){
    .login-row{
      padding: 0!important;
      }
    }
  @media (min-width: 768px) and (max-width: 991px){
    .set-width{
      width: 25rem;}
    .login-btn{
      float: right;
      margin-right: 11%;

      }
    }
  .card-custom{    box-shadow: 0px 0px 8px 0px;}
</style>
