<template>

  <div>
    <h2 class="text-center login-class pb-2 rounded-sm">Reset Password</h2>
    <div class="card custom-login-card ">
      <div class="card-body ">

          <div class="form-group ">
            <label >Email</label>
            <input type="email" class="form-control" v-model="email" autocomplete="email" autofocus>
          </div>

          <div class="reg-btn">
            <button v-if="!isShow" type="submit" class="btn btn-primary rounded-pill login-button"  @click="submit">Send Reset Password Link</button>
            <circle2  v-if="isShow" class="solent-spinner col-2 "></circle2>
          </div>
      </div>
    </div>
  </div>
</template>
<script >
	export default {
		name: 'password-reset',
		data () {
			return {
				email: '',
        isShow:false
			}
		},
		components: {
		},
		computed: {
		},
		methods: {
      submit () {
		      this.isShow = true
		      this.$store.dispatch('Auth/createToken', {
			      email: this.email,
		      }).then(res => {
			      if(res.status === 200) {
				      this.$toastr.s('Please check your email.', 'Success')
				      this.isShow = false
			      }else {
				      this.isShow = false
				      this.$toastr.e('Something went wrong please try again.', 'Error')
			      }
		      }).catch(err => {
			      this.isShow = false
			      if(err.response.status === 422){
				      // _.forEach(err.response.data.message, error => {
					     //  this.$toastr.e(error, 'Error')
					      this.$toastr.e(err.response.data.errors.email[0], 'Error')
				      // })
			      }else {
				      this.$toastr.e('Something went wrong please try again.', 'Error')
			      }
		      })
	      }
		},
		mounted () {
		},
	}
</script >
<style scoped>
  /*.card{flex-direction: initial;}*/

  /*.mb-22-1em{margin-bottom: 22.1em}*/
.card-header{
  background-color: #F99800;
  color: white;

  }
  .login-class{
    background-color: #F99800;
    color: #ffffff;
    padding: 3px;
    margin-bottom: 5%;
    }
  .custom-login-card .card-body{
    background-color: #132E41;
    padding: 7%;
    color: white;
    }
  .register-button{
    background-color: #FC9301;
    color: white;
    text-align: center;
    padding-left: 7%;
    padding-right: 7%;
    align-content: center;
    }
  .login-button{
    text-align: center;
    padding-left: 7%;
    padding-right: 7%;
    }
  .reg-btn{
    text-align: center;
    }
  @media screen and (max-width: 560px){
    .login-row{
      padding: 0!important;
      }
    }
  @media (min-width: 768px) and (max-width: 991px){
    .set-width{
      width: 25rem;}
    .login-btn{
      float: right;
      margin-right: 11%;

      }
    }
  .card-custom{    box-shadow: 0px 0px 8px 0px;}

  .solent-spinner {
    margin-left: 43%;
  }
</style>
