<template >
	<div class="container container-custom">
		<div class="container container-relative">
			<div class="row justify-content-center ">
				<div class="col-md-12 padding-rightClass">
					<div class="card mt-3em-b-1-w custom-padding-bottom">
						<div class=" heading-color col-md-6 col-sm-12 col-lg-3 col-xs-12 card-custom-create user-strategy">Edit Profile</div>
						<div class="card-body card-body-custom">
							<div class="row">
								<div class="col-md-3"></div>

					<div class="col-md-6">
						<div class="col-sm-12">
							<div class="form-group">
								<label>Email</label >
								<label class="form-control overflow-hidden" >{{this.email}}</label >
							</div>
						</div>
						<div class="col-sm-12">
								<div class="form-group">
									<label >Name</label >
									<input type = "text" name="name" class="form-control overflow-hidden" v-model="$v.name.$model"
									       :class="{ 'border-danger': $v.name.$error }">
								</div>
							</div>
						<div class="col-sm-12">
								<div class="form-group">
									<label>Phone Number</label >
									<input type = "number" class="form-control overflow-hidden" v-model="$v.phoneNo.$model"
									       :class="{ 'border-danger': $v.phoneNo.$invalid }">
								</div>
							</div>
						<button type="submit" class="btn ml-3" @click="update()">Update</button>
					</div>
					<div class="col-md-3"></div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template >
<script >
	import { mapGetters } from 'vuex'
	import _ from 'lodash'
	import { required, requiredIf, between, helpers, email } from 'vuelidate/lib/validators'

	export default {
		name: "profile-edit",
		data () {
			return {
				// editProfile: {
					name: '',
					email: '',
					phoneNo: ''
				// }
			}
		},
		validations: {
			name: {required},
			email: {required,email},
			phoneNo: {required},
		},

		computed: {
			...mapGetters({
				              profile: 'Auth/profile',
			              }),
			editProfileValidation () {
				return  ! this.$v.name.$error &&
                ! this.$v.phoneNo.$invalid
			},

		},
		methods: {
			fetchSetups () {
				if(_.isEmpty(this.profile)) {
					this.$store.dispatch('Auth/fetch')
					    .then(res => {
						    this.updateFields()
					    })
				}else{
						this.updateFields()
				}
			},
			updateFields () {
				this.name = this.profile.name
				this.email = this.profile.email
				this.phoneNo = this.profile.phoneNo

			},
			update () {
				if(! _.isEmpty(this.profile) && this.editProfileValidation) {
					this.$store.dispatch('Auth/update', {
						id: this.profile.id,
						name: this.name,
						phoneNo: this.phoneNo,
					}).then(res => {
						    if (res.code === 200) {
							    this.$toastr.s('Your profile updated successfully.', 'Success')
						    }
					    }).catch(err => {
						this.isShow = true
						if(err.response.status === 422){
							_.forEach(err.response.data.message, error => {
								this.$toastr.e(error, 'Error')
							})
						}else {
							this.$toastr.e('Something went wrong, please try again.', 'Error')
						}
					})
				}
			},
			sessionValidate () {
				if(!this.$auth.isAuthenticated()){
					this.$router.push('/login')
				}
			}
		},
		mounted () {
			this.sessionValidate()
			this.fetchSetups()
		}
	}
</script >
<style scoped >
	.btn-danger{
background-color:#DC3545;
		}

	.custom-padding-bottom{
		padding-bottom: 8%;
		}
</style >