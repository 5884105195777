<template>
<!--    <div class="pb-5 ">-->
    <div class="container container-custom compare-container">
        <div class="container container-relative">
            <div v-if="strategies" class="top-class">
               <div class="row">
                <button @click="redirect" class="btn refresh-button refresh-button-compare"
                        :class="{'bg-secondary': comparisonIds.length < 2}">
                    Compare</button >
               </div>
                <div class="table-class">
                    <vue-bootstrap4-table :rows="strategies" :columns="columns" :config="config" >
                        <template slot="id" slot-scope="props" >
                            <router-link  v-if="props.row.isChartGenerated" :to= "{name: 'user-strategy-graph',
                                    params: {id: props.cell_value}}" class="btn ">
                                <i class="fas fa-chart-line"></i>
                            </router-link>
                            <circle2  class=" solent-spinner ml-1" v-if="!props.row.isChartGenerated"></circle2>
                        </template>
                        <template slot="params" slot-scope="props" >
                            <span class="text-left" style="white-space: pre-line;" v-html="props.cell_value"> </span>
                        </template>
                        <template slot="filePath" slot-scope="props" >
                            <button class="btn" @click="download(props.row.button)" v-if="props.row.isFileGenerated">
                                <i class="fas fa-cloud-download-alt"></i>
                            </button>
                            <circle2  class=" solent-spinner ml-4" v-if="!props.row.isFileGenerated"></circle2>
                        </template>
                        <template slot="selectedMarketsLength" slot-scope="props">
                            <div v-if="!props.row.isVolatilityStrategy">
                                <b-button v-b-modal.modal-1 title="Markets" @click="marketMapper( props.row)"
                                          class="length-button count-btn-custom">{{props.cell_value}} </b-button>
                            </div>
                            <div v-if="props.row.isVolatilityStrategy">
                                <span class=""> N/A </span>
                            </div>
                        </template>

                        <template slot="comparison" slot-scope="props">
                            <b-form-checkbox-group id="checkbox-group-2" v-model="comparisonIds" name="flavour-2">
                                <b-form-checkbox :value="props.row.id"></b-form-checkbox>
                            </b-form-checkbox-group>
                        </template>
                    </vue-bootstrap4-table>
                </div>
                <div>
                    <b-modal v-model="isModalShow" title="Markets List" >


                        <table class=" table table-bordered table-list">
                            <thead>
                            <tr class="rowheadcolor ">
                                <th>Broad Category</th>
                                <th>Market</th>
                                <th  v-if="popUpTfWeights.length">Weights</th>
                            </tr>
                            </thead>
                            <tbody >
                            <tr v-for="(market, index) in popUpMarkets">
                                <td v-text="market.broadCategory"></td>
                                <td v-text="market.market"></td>
                                <td v-text="popUpTfWeights[index]" v-if="popUpTfWeights.length"></td>
                            </tr>

                            </tbody>
                        </table>
                    </b-modal>
                </div>
            </div>
        </div>
    </div>
<!--    </div>-->
</template>
<script>
import VueBootstrap4Table from 'vue-bootstrap4-table'
import { mapGetters } from 'vuex'
import {Strategy} from '../../enums/Strategy'
import {TFWeightChoice} from '../../enums/TFWeightChoice'
export default {
	name: 'strategies-comparison',
	data () {
		return {
      // comparisonIds : [],
        options: [],

		    popUpMarkets: [],
		    popUpTfWeights: [],
        isModalShow: false,
        timer: [],
			columns: [
				{label: "User Strategy Name", name: "name", sort: true,},
				{label: "Strategy", name: "strategyName", sort: true,},
				{label: "Currency", name: "currency", sort: true,},
				{label: "Parameters", name: "params", sort: true,},
				{label: "From Month", name: "fromDate", filter: { type: "date", placeholder: "From Month"},
					sort: true,
				},
				{label: "To Month", name: "toDate", filter: {type: "date", placeholder: "To Month"},
					sort: true,
				},
				{label: "Total Markets",   name: "selectedMarketsLength",sort: true,},
        {label:"Comparison" , name:"comparison",sort:true },

        // {label:"Download" , name:"filePath", class:"download-heading" }

	],
			// actions: [
			// 	{
			// 		btn_text: "Download",
			// 		event_name: "on-download",
			// 		class: "btn btn-primary my-custom-class",
			// 		event_payload: {
			// 			msg: "my custom msg"
			// 		}
			// 	}
			// ],
				config: {
				checkbox_rows: false,
				rows_selectable: true,
				card_title: "Your Strategies",
				// pagination: true,
				// pagination_info: true,
				// num_of_visibile_pagination_buttons: 7,
				per_page: 10,
				// per_page_options: [5, 10, 15, 20],
				// highlight_row_hover: true,
				show_refresh_button: false,
				show_reset_button: false,
			}
		}
	},
	components: {
		VueBootstrap4Table
	},
	computed: {
		...mapGetters({
			              strategiesComparison: 'UserStrategy/validForComparison',
			              find: 'UserStrategy/find',
			              markets: 'market/get',
			              allFileGenerated: 'UserStrategy/allFileGenerated',
			              allChartGenerated: 'UserStrategy/allChartGenerated',
		              }),
    comparisonIds: {
			set(ids) {
				let totalSelected = this.$store.getters['UserStrategy/getComparisonIds'].length
				if(ids && totalSelected <= 5) {return this.$store.commit('UserStrategy/setComparisonIds', ids)}
      },
			get() {return this.$store.getters['UserStrategy/getComparisonIds']},
    },
		strategies() {
			return _.chain(this.strategiesComparison).map(strategy=> {
				return {
					name: strategy.name,
					params: this.prepareStrategyParams(strategy.strategy.name.toLowerCase(), strategy.strategyParams),
					fromDate: strategy.fromDateFormatted,
					toDate: strategy.toDateFormatted,
					strategyName: strategy.strategy.name,
					currency: strategy.currency,
					isVolatilityStrategy: this.isVolatilityStrategy(strategy.strategy.name),
          id: strategy.id,
          button: strategy.isFileGenerated ? strategy.id : '',
					isFileGenerated: strategy.isFileGenerated,
					isChartGenerated: strategy.isChartGenerated,
          filePath: strategy.filePath,
          selectedMarkets: strategy.selectedMarkets,
          tfWeights: strategy.trendFollowingPercentage,
          selectedMarketsLength: strategy.selectedMarkets.length,
          comparison: {text: strategy.id, value: strategy.id},
          compare: false
        }
			}).orderBy(['id'], ['desc']).value()
		},
	},
  watch: {
    strategiesComparison() {
	  	if(this.allChartGenerated && this.allFileGenerated){
        _.each(this.timer, timer => {
			      clearInterval(timer)
            _.remove(this.timer, timer)
        })
      }else{
		    this.setInterval()
      }
    },
    timer() {
	  	localStorage.setItem(process.env.VUE_APP_LOCAL_STORAGE_INTERVAL_ID_LABEL, this.timer)
    },
	  comparisonIds () {
		  let totalSelected = this.$store.getters['UserStrategy/getComparisonIds'].length
		  if(totalSelected > 5 ){
			  this.$toastr.e('Maximum 5 strategies allowed for compare.', 'Error')
		  }
	  }
  },
	methods: {
		populateOptions () {
			_.map(this.strategiesComparison, strategy => {
				return {value: strategy.id}
      })
    },
    isVolatilityStrategy(strategy) {
    	return strategy.toLowerCase() === Strategy.VOL_TARGET_FILTER
    },
		prepareStrategyParams (strategy, params) {
			switch (strategy){
        case Strategy.MOMENTUM:
        	return 'Top ' + params.momentum.pickTopValue
        case Strategy.TREND_FOLLOWING:
        	return _.capitalize(params.trendFollowing.choice) + ' Weights'
        case Strategy.VOL_TARGET_FILTER:
        	return ("Strategy " +
                 this.find(params.volatilityTriggering.strategy).name  +
                 ', '+
                 "\n"  +
                 "Target Vol " + params.volatilityTriggering.targetVolume)

        default:
        	return 'N/A'
      }
		},
		fetchSetups () {
			if(this.strategiesComparison.length === 0) {
				this.$store.dispatch('UserStrategy/fetch')
				this.$store.dispatch('market/fetch')
			}
		},
		marketMapper (row) {
      this.popUpTfWeights = []
			if(row.strategyName.toLowerCase() === Strategy.TREND_FOLLOWING) {
					if(row.params === TFWeightChoice.EQUAL_WEIGHT) {
						_.each(row.selectedMarkets, market => {
							this.popUpTfWeights.push(100/row.selectedMarketsLength)
            })
					}else{
						this.popUpTfWeights = row.tfWeights
          }
			}
			this.popUpMarkets = _.chain(this.markets).filter(market => {
				return _.includes(row.selectedMarkets, market.id)
      }).map(market => {
      	return {
      	    'id': market.id,
      	    'broadCategory': market.broadCategory.name,
            'market': market.name,
      	}
      }).value()
        this.isModalShow = true
		},
    download (id) {
	    this.$store.dispatch('UserStrategy/download', id )
    },
    refresh () {
	    this.$store.dispatch('UserStrategy/fetch')
    },
		setInterval() {
			let timer
      if(this.timer.length === 0) {
	      window.intervalTimer = timer = setInterval(() => {
		      this.refresh()
	      }, 20 * 1000)
	      this.timer.push(timer)
      }
		},
		sessionValidate () {
			if(!this.$auth.isAuthenticated()){
				this.$router.push('/login')
			}
		},
    redirect () {
			if(this.comparisonIds < 2){
				this.$toastr.e('Please select two or more strategies for comparison.', 'Error')
			}else {
				this.$router.push('/comparison/result')
			}
		}
	},
	async mounted () {
		await this.sessionValidate()
		this.fetchSetups()
		this.setInterval()
		this.$store.commit('UserStrategy/setComparisonIds', [])
		// let intervalId = localStorage.getItem(process.env.VUE_APP_LOCAL_STORAGE_INTERVAL_ID_LABEL)
		// clearInterval(parseInt(intervalId))
		// clearInterval(window.intervalTimer)
	}
}

</script>

<style>

    .table-class .card .card-body .vbt-table-wrapper table{
        width: 100%;
        }
    @media screen and (max-width: 1332px) {
        .table-class .card .card-body .vbt-table-wrapper table {
            width : 65.5rem;
            }
        }
    .table-class .card .card-body .vbt-table-wrapper table thead .vbt-column-header{
        border-right: 1px solid #DEE2E6 !important;
    }
    .table-class .card .card-body .vbt-table-wrapper table thead .vbt-column-header:last-child{

        border-right: none !important;
    }

    .container-custom .container .top-class .table-class .card .card-header{
        border-top-right-radius: 25px;
        border-bottom-right-radius: 25px;
        width: 30%;
        }
    @media screen and (max-width: 480px) {
        .container-custom .container .top-class .table-class .card .card-header{
            width : 100% !important;
            margin-bottom: 11%;
        }
        .refresh-button{
            margin-top: 44px;
            right: 7% !important;
            }

        /*margin-top: 15px;*/
    }
    @media screen and (max-width: 636px) {
        .compare-container .container .top-class .table-class .card .card-header{
            width : 100% !important;
            margin-bottom: 11%;
        }
        .refresh-button-compare{
            margin-top: 44px;
            right: 7% !important;
            }

        /*margin-top: 15px;*/
    }
    .refresh-button-compare{
        background-color: #FC9401;
        }
    @media screen and (max-width: 785px) {
        .container-custom .container .top-class .table-class .card .card-header{
            width : 70% ;

        }

    }
    .container-custom .container .top-class .table-class .card{
        border-top: 1px solid white;
        top: 15px;
        position: relative;
    }
    .container-custom .container .top-class .table-class .card, .container-custom .container .top-class .table-class .card .card-body{
        border-top:1px solid white;

    }
    .pagination .input-group input{
        font-size: 11px;
        height: 38px;
    }

    /*scoped*/
    .count-btn-custom{
        background: white;
        color: black !important;
        border: 1px solid #59B6DB;
        padding: 3px;
    }
    .count-btn-custom:hover{ background: #59B6DB52}
    .container-relative{
        position: relative;
    }
    .container-custom{
        box-shadow: 0px 0px 8px 0px;
        padding-bottom: 2em;

    }
    .refresh-button{
        position: absolute;
        top: 1.5em;
        right: 36px;
        z-index: 99;
    }
    .table-class .card .card-body .table-responsive .table>tbody>tr:nth-child(odd)>td {
        background-color: #FEF1DE !important;
        }
</style>
