<template >
	<div>
	<section class="login-header-class">
		<img src = "../assets/images/Login.png" alt = "" class="login-logo">
	</section>

	<div class="container">
	<div class="row p-5 login-row">
	<div class="col-md-6">
		<h2>About Us</h2>
		<hr class="hr-reg">
		<p class="pr-4">
			Solent is the brainchild of Roderick Collins, Professors Andrew Clare and Steve Thomas of the Cass Business School and Dr James Seaton. The central proposition is that Financial Markets are
			unforecastable, using the macro-economics, subjective market analysis and human judgement advocated by many Investment Strategists. The solution to this conclusion is not passive investment, since passive leaves the investor exposed to all the volatility of the market which, as we have seen with the FTSE 100, can lead to a draw down of 60%.
		</p>
	</div>
		<div class="col-md-6">
			<slot/>
		</div>
	</div>
	</div>


		<div class="login-bottom-class pb-2">
			<p class="pt-2 mb-0">@ Solent Investments. All Rights Reserved</p>
			<p class=" mb-1 pb-0"><a href="http://Solentsis.com/" class="text-white text-decoration-none mr-1">Home </a>  | <a href="https://www.solentsis.net/contact/" target="_blank"
			                                                                                        class="ml-1 text-white text-decoration-none">Contact Us</a></p>
		</div>
	</div>
</template >
<script >
	export default {
		name: "GuestLayout"
	}
</script >
<style scoped >
	.login-header-class{
		/*height: 450px;*/
		/*background-position: 50% 90%;*/
		height: 304px;
		background-position: 45% 95%;
		background-size: cover;
		/*background-repeat: no-repeat;*/
		background-image: url("../assets/images/background.png");
		/*background-position: center center;*/
		/*position: relative;*/
		}
	.login-bottom-class{
		background-color: #132E3F;
		border-top: 5px solid #FE9400;
		justify-content : center;
		text-align: center;
		color: white;
		padding : 8px;
		}
	.login-class{
		background-color: #F99800;
		color: #ffffff;
		padding: 2px;
		margin-bottom: 5%;
		}
	.custom-login-card .card-body{
		background-color: #132E41;
		padding: 7%;
		color: white;
		}
	.register-button{
		background-color: #FC9301;
		color: white;
		text-align: center;
		padding-left: 7%;
		padding-right: 7%;
		align-content: center;
		}
	.login-button{
		text-align: center;
		padding-left: 7%;
		padding-right: 7%;
		}
	.reg-btn{
		text-align: center;
		}
	@media screen and (max-width: 560px){
		.login-row{
			/*margin*/
			padding: 0!important;
			}
		}
	.hr-reg{
		border: 1px solid;
		}
	.login-row{
		/*margin*/
		margin-top: -2%;
		padding-top: 0!important;
		}
	.login-logo{
		width: 350px;
		padding: 66px;
		}


	@media screen and (max-width:344px) {
		.login-logo{
			width: 223px !important;
			padding: 44px !important;
			}
		.login-header-class{
			height: 200px;
			}
		}
	@media screen and (max-width:224px) {
		.login-logo{
			width: 200px !important;
			padding: 44px !important;
			}
		}
</style >