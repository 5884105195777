<template>
  <div id="app" >
	  <component :is="layout">
			<router-view/>
	  </component>
		<!--<Layout :isAuth="this.$auth.isAuthenticated()"/>-->
    <!--<AuthLayout v-if="this.$auth.isAuthenticated()"/>-->
    <!--<GuestLayout v-if="!this.$auth.isAuthenticated()"/>-->
    <!--<HeaderMenu :isAuth="this.$auth.isAuthenticated()"/>-->
    <!--<router-view/>-->
    <!--<footer-primary />-->
  </div>
</template>

<script>
	import router from './router'
	import TopMenuBarGuest from '@/components/TopMenuBarGuest.vue'
	import TopMenuBarAuthenticated from '@/components/TopMenuBarAuthenticated.vue'
	import HeaderMenu from '@/components/HeaderMenu.vue'
	import FooterPrimary from '@/components/FooterPrimary.vue'
	import Layout from '@/components/Layout.vue'
	import {mapGetters} from 'vuex'
	export default {
		name: 'App',
		components: {TopMenuBarGuest,
                 TopMenuBarAuthenticated,
                 HeaderMenu,
                 FooterPrimary,
                 Layout
    },
		computed: {
      layout() {
      	return this.$route.meta.layout
      }
    },
		methods: {
			routerBeforeEach() {
				router.beforeEach((to, from, next) => {
					if (to.matched.some(record => record.meta.requiresAuth)) {
						this.$auth.isAuthenticated() ? next() : this.$auth.logout()
					} else {
						next()
					}
				})
      },
      validateSession() {
	      if (!this.$auth.isAuthenticated()) {
		      this.$auth.logout()
	      }
      }
    },
		mounted () {
			// this.validateSession()
			this.routerBeforeEach()
		},
	}
</script>


<style>



</style>
