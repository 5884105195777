import axios from 'axios'
import common from '../store/common'
import {Boolean} from '../enums/Boolean'
import router from '../router'

export default function (Vue) {
	Vue.auth = {
		sendSocialToken (token) {
			return axios.get(process.env.VUE_APP_BASE_URL +
			                 process.env.APP_PREFIX +
			                 process.env.DATA_SERVICE_VERSION +
			                 'social-token/' +
			                 token.params.network +
			                 '/' + localStorage.getItem('channel').toUpperCase(),
				{params: {code: token.query.code}}).then(response => {
				return response
			}).catch(error => {
				return Promise.reject(common.prepareCatch(error))
			})
		},
		socialLogin (network) {
			return axios.get(process.env.VUE_APP_BASE_URL + 'login/' + network, {}).then(response => {
				return response
			})
		},
		login (credentials) {
			let params = {
				client_id: process.env.VUE_APP_CLIENT_ID,
				client_secret: process.env.VUE_APP_CLIENT_SECRET,
				grant_type: 'password',
				username: credentials.email,
				password: credentials.password,
			}
			return axios.post(process.env.VUE_APP_BASE_URL + 'oauth/token', params).then(response => {
				let data = response.data;
				return axios.get('/validate-user', {
					headers: {'Authorization': 'Bearer ' + data.access_token}
				}).then(res => {
					let isSessionSet = this.setSession({
						                                   token: data.access_token,
						                                   expiredAt: data.expires_in,
						                                   type: Boolean.NO
					                                   })
					return Promise.resolve(isSessionSet)
				}).catch(error => {
					return Promise.reject(error)
				})
			}).catch(error => {
				return Promise.reject(common.prepareCatch(error))
			})
		},
		// userValidate(token) {
		// 	return axios.get('/validate-user', {
		// 		headers: {
		// 			'Authorization': 'Bearer ' + token
		// 		}
		// 	}).then(res => {
		// 		return Promise.resolve(
		// 		{status: res.data.success, message: res.data.message})
		// 	}).catch(error => {
		// 		return Promise.reject(common.prepareCatch(error))
		// 	})
		// },
		setSession (params) {
			if (params.token && params.expiredAt) {
				let expiredAt = JSON.stringify(
					 parseInt(params.expiredAt) * 1000 + new Date().getTime()
				)
				localStorage.setItem(process.env.VUE_APP_LOCAL_STORAGE_TOKEN_LABEL, params.token)
				localStorage.setItem(process.env.VUE_APP_LOCAL_STORAGE_EXPIRED_AT_LABEL, expiredAt)
				localStorage.setItem(process.env.VUE_APP_LOCAL_STORAGE_IS_ADMIN_LABEL, params.type)
				axios.defaults.headers.common['Authorization'] = `Bearer ${params.token}`;
				return localStorage.hasOwnProperty(process.env.VUE_APP_LOCAL_STORAGE_TOKEN_LABEL) &&
				       localStorage.hasOwnProperty(process.env.VUE_APP_LOCAL_STORAGE_EXPIRED_AT_LABEL)
			}
		},
		register (data) {
			let params = {
				name: data.name,
				email: data.email,
				phone: data.phoneNumber,
				password: data.password,
				password_confirmation: data.confirmPassword,
			}
			return axios.post(process.env.VUE_APP_BASE_URL + 'user/register', params).then(response => {
				return response.data
			}).catch(error => {
				return Promise.reject(common.prepareCatch(error))
			})
		},
		recoverPassword (email, channel = null) {
			let params = {
				email: email,
				channel: channel
			}
			return axios.post(process.env.VUE_APP_BASE_URL +
			                  process.env.APP_PREFIX +
			                  process.env.DATA_SERVICE_VERSION + 'password/email', params).then(response => {
				return response.data
			}).catch(error => {
				return Promise.reject(common.prepareCatch(error))
			})
		},
		resetPassword (data) {
			let params = {
				email: data.email,
				token: data.token,
				password: data.password,
				password_confirmation: data.confirmPassword
			}
			return axios.post(process.env.VUE_APP_BASE_URL +
			                  process.env.APP_PREFIX +
			                  process.env.DATA_SERVICE_VERSION + 'password/reset', params).then(response => {
				return response.data
			}).catch(error => {
				return Promise.reject(common.prepareCatch(error))
			})
		},
		getToken () {
			let token     = localStorage.getItem(process.env.VUE_APP_LOCAL_STORAGE_TOKEN_LABEL)
			let expiresAt = localStorage.getItem(process.env.VUE_APP_LOCAL_STORAGE_EXPIRED_AT_LABEL)
			if (! token || ! expiresAt) {
				return null
			}
			if (new Date().getTime() > JSON.parse(expiresAt)) {
				this.logout()
			} else {
				return token
			}
		},
		isAuthenticated () {
			return ! ! this.getToken()
		},
		isAdminLogin () {
			return JSON.parse(localStorage.getItem(process.env.VUE_APP_LOCAL_STORAGE_IS_ADMIN_LABEL))
		},
		logout () {
			common.logout()
		},
	}
	Object.defineProperties(Vue.prototype, {
		$auth: {
			get: () => {
				return Vue.auth
			}
		}
	})
}
