<template>
<!--    <div class="pb-5 ">-->
    <div class="container container-custom">
        <div class="container container-relative">
            <div v-if="strategies" class="top-class">
                <!--<button class="btn float-right refresh-button" @click="refresh"><i class="fas fa-sync"></i></button>-->
                <button class="btn refresh-button" @click="refresh"><i class="fas fa-sync"></i></button>

                <div class="table-class">
                    <vue-bootstrap4-table :rows="strategies" :columns="columns" :config="config" >
                        <template slot="id" slot-scope="props" >
                            <router-link v-if="props.row.isChartGenerated" :to= "{name: 'user-strategy-graph',
                                    params: {id: props.cell_value}}" class="btn resultBtn graph-icon">
                                <i class="fas fa-chart-line"></i>
	                            <span class="dot" v-text="props.row.newMessageCount" v-if="props.row.isChartGenerated && props.row.newMessageCount"></span>
                            </router-link>
                            <circle2  class=" solent-spinner ml-1" v-if="!props.row.isChartGenerated"></circle2>
                        </template>
                        <template slot="params" slot-scope="props" >
                            <span class="text-left" style="white-space: pre-line;" v-html="props.cell_value"> </span>
                        </template>
                        <template slot="filePath" slot-scope="props" >
                            <button class="btn" @click="download(props.row.button)" v-if="props.row.isFileGenerated">
                                <i class="fas fa-cloud-download-alt"></i>
                            </button>
                            <circle2  class=" solent-spinner ml-4" v-if="!props.row.isFileGenerated"></circle2>
                        </template>
                        <template slot="deleteUserStrategy" slot-scope="props" >
                            <button class="btn" @click="deleteStrategy(props.row.id)">
                                <i class="fa fa-trash"></i>
                            </button>
                        </template>
                        <template slot="selectedMarketsLength" slot-scope="props">
                            <div v-if="!props.row.isVolatilityStrategy">
                                <b-button v-b-modal.modal-1 title="Markets" @click="marketMapper( props.row )"
                                          class="length-button count-btn-custom">{{props.cell_value}} </b-button>
                            </div>
                            <div v-if="props.row.isVolatilityStrategy">
                                <span class=""> N/A </span>
                            </div>
                        </template>

                    </vue-bootstrap4-table>
                </div>
                <div>
                    <b-modal v-model="isModalShow" title="Markets List" >


                        <table class=" table table-bordered table-list">
                            <thead>
                            <tr class="rowheadcolor ">
                                <th>Broad Category</th>
                                <th>Market</th>
                                <th  v-if="popUpTfOrEwWeights.length">Weights</th>
                            </tr>
                            </thead>
                            <tbody >
                            <tr v-for="(market, index) in popUpMarkets">
                                <td v-text="market.broadCategory"></td>
                                <td v-text="market.market"></td>
                                <td v-text="popUpTfOrEwWeights[index]" v-if="popUpTfOrEwWeights.length"></td>
                            </tr>

                            </tbody>
                        </table>
                    </b-modal>
                </div>
            </div>
        </div>
    </div>
<!--    </div>-->
</template>
<script>
import VueBootstrap4Table from 'vue-bootstrap4-table'
import { mapGetters } from 'vuex'
import {Strategy} from '../../enums/Strategy'
import {TFWeightChoice} from '../../enums/TFWeightChoice'
import Swal from 'sweetalert2'
export default {
	name: 'user-strategies',
	data () {
		return {
		    popUpMarkets: [],
		    popUpTfOrEwWeights: [],
        isModalShow: false,
        timer: [],
			columns: [
				{label: "User Strategy Name", name: "name", sort: true,},
				{label: "Strategy", name: "strategyName", sort: true,},
				{label: "Currency", name: "currency", sort: true,},
				{label: "Parameters", name: "params", sort: true,},
				{label: "From Month", name: "fromDate", filter: { type: "date", placeholder: "From Month"},
					sort: true,
				},
				{label: "To Month", name: "toDate", filter: {type: "date", placeholder: "To Month"},
					sort: true,
				},
				{label: "Total Markets",   name: "selectedMarketsLength",sort: true,},
        {label:"Result" , name:"id", },
        {label:"Download" , name:"filePath", class:"download-heading" },
        {label:"Delete" , name:"deleteUserStrategy" }

	],
			// actions: [
			// 	{
			// 		btn_text: "Download",
			// 		event_name: "on-download",
			// 		class: "btn btn-primary my-custom-class",
			// 		event_payload: {
			// 			msg: "my custom msg"
			// 		}
			// 	}
			// ],
				config: {
				checkbox_rows: false,
				rows_selectable: true,
				card_title: "Your Strategies",
				// pagination: true,
				// pagination_info: true,
				// num_of_visibile_pagination_buttons: 7,
				per_page: 10,
				// per_page_options: [5, 10, 15, 20],
				// highlight_row_hover: true,
				show_refresh_button: false,
				show_reset_button: false,
			}
		}
	},
	components: {
		VueBootstrap4Table
	},
	computed: {
		...mapGetters({
			              userStrategies: 'UserStrategy/get',
			              find: 'UserStrategy/find',
			              markets: 'market/get',
			              allFileGenerated: 'UserStrategy/allFileGenerated',
			              allChartGenerated: 'UserStrategy/allChartGenerated',
		              }),
		strategies() {
			return _.chain(this.userStrategies).map(strategy=> {
				return {
					name: strategy.name,
					params: this.prepareStrategyParams(strategy.strategy.name.toLowerCase(), strategy.strategyParams),
					fromDate: strategy.fromDateFormatted,
					toDate: strategy.toDateFormatted,
					strategyName: strategy.strategy.name,
					currency: strategy.currency,
					isVolatilityStrategy: this.isVolatilityStrategy(strategy.strategy.name),
          id: strategy.id,
          button: strategy.isFileGenerated ? strategy.id : '',
					isFileGenerated: strategy.isFileGenerated,
					isChartGenerated: strategy.isChartGenerated,
          filePath: strategy.filePath,
          selectedMarkets: strategy.selectedMarkets,
          tfWeights: strategy.trendFollowingPercentage,
          ewWeights: strategy.equalWeightPercentage,
          selectedMarketsLength: strategy.selectedMarkets.length,
					newMessageCount: strategy.newMessages.length
        }
			}).orderBy(['id'], ['desc']).value()
		},
	},
  watch: {
	  // allFileGenerated() {
		//  this.setInterval()
    // },
	  userStrategies() {
	  	if(this.allChartGenerated && this.allFileGenerated){
        _.each(this.timer, timer => {
			      clearInterval(timer)
            _.remove(this.timer, timer)
        })
      }else{
		    this.setInterval()
      }
    },
    timer() {
	  	localStorage.setItem(process.env.VUE_APP_LOCAL_STORAGE_INTERVAL_ID_LABEL, this.timer)
    }
  },
	methods: {
    isVolatilityStrategy(strategy) {
    	return strategy.toLowerCase() === Strategy.VOL_TARGET_FILTER
    },
		prepareStrategyParams (strategy, params) {
			switch (strategy){
        case Strategy.MOMENTUM:
        	return 'Top ' + params.momentum.pickTopValue
        case Strategy.TREND_FOLLOWING:
        	return _.capitalize(params.trendFollowing.choice) + ' Weights'
        case Strategy.VOL_TARGET_FILTER:
        	return ("Strategy " + this.find( params.volatilityTriggering.strategy).name  +
                  "\n" + "Target Vol "
                  + params.volatilityTriggering.targetVolume) + "%"

        default:
        	return 'N/A'
      }
		},
		fetchSetups () {
			if(this.userStrategies.length === 0) {
				this.$store.dispatch('UserStrategy/fetch')
				this.$store.dispatch('market/fetch')
			}
		},
		marketMapper (row) {
      this.popUpTfOrEwWeights = []
      let weight = (row.strategyName.toLowerCase() === Strategy.TREND_FOLLOWING) ? row.tfWeights : row.ewWeights
			if(row.strategyName.toLowerCase() === Strategy.TREND_FOLLOWING ||
         row.strategyName.toLowerCase() === Strategy.EQUAL_WEIGHT ||
			   row.strategyName.toLowerCase() === Strategy.FLEXIBLE_WEIGHTS_YOU_CHOOSE) {
					if(row.params === TFWeightChoice.EQUAL_WEIGHT) {
						_.each(row.selectedMarkets, market => {
							this.popUpTfOrEwWeights.push(100/row.selectedMarketsLength)
            })
					}else{
						this.popUpTfOrEwWeights =  weight
          }
			}
			this.popUpMarkets = _.chain(this.markets).filter(market => {
				return _.includes(row.selectedMarkets, market.id)
      }).map(market => {
      	return {
      	    'id': market.id,
      	    'broadCategory': market.broadCategory.name,
            'market': market.name,
      	}
      }).value()
        this.isModalShow = true
		},
    download (id) {
	    this.$store.dispatch('UserStrategy/download', id )
    },
		async deleteStrategy (id) {
			let isConfirmed = await Swal.fire({
				                                  title: 'Confirmation!',
				                                  text: 'Are you sure you want to delete?',
				                                  icon: 'question',
				                                  showConfirmButton: true,
				                                  showCancelButton: true,
				                                  confirmButtonText: 'Yes',
				                                  cancelButtonText: 'Cancel'
			                                  })
      if(isConfirmed.value) {
	      this.$store.dispatch('UserStrategy/destroy', id).then(res => {
		      this.refresh()
		      Swal.fire({
			                icon: 'success',
			                title: 'Strategy successfully deleted.',
			                showConfirmButton: false,
			                timer: 2500
		                })
	      }).catch(err => {
		      Swal.fire({
			                icon: 'error',
			                title: 'Something went wrong, please try again.',
			                showConfirmButton: false,
			                timer: 2500
		                })
	      })
      }
    },
    refresh () {
	    this.$store.dispatch('UserStrategy/fetch')
    },
		setInterval() {
			let timer
      if(this.timer.length === 0) {
	      window.intervalTimer = timer = setInterval(() => {
		      this.refresh()
	      }, 20 * 1000)
	      this.timer.push(timer)
      }
		},
		sessionValidate () {
			if(!this.$auth.isAuthenticated()){
				this.$router.push('/login')
			}
		}
	},
	async mounted () {
		await this.sessionValidate()
		this.fetchSetups()
		this.setInterval()
	}
}

</script>

<style>
    .table-class .card .card-body .vbt-table-wrapper table thead :nth-child(2) th .float-right{
        display: none;
        }
    .table-class .card .card-body .vbt-table-wrapper table thead :nth-child(2) :nth-child(1)  {
            width:17%;
        }
    .table-class .card .card-body .vbt-table-wrapper table thead :nth-child(2) :nth-child(2)  {
            width:8%;
        }
    .table-class .card .card-body .vbt-table-wrapper table thead :nth-child(2) :nth-child(3)  {
            width:9%;

        }
    .table-class .card .card-body .vbt-table-wrapper table thead :nth-child(2) :nth-child(4)  {
            width:11%;

        }

    .table-class .card .card-body .vbt-table-wrapper table{
        width: 100%;
        }
    @media screen and (max-width: 1332px) {
        .table-class .card .card-body .vbt-table-wrapper table {
            width : 65.5rem;
            }
        }
    .table-class .card .card-body .vbt-table-wrapper table thead .vbt-column-header{
        border-right: 1px solid #ffff !important;
    }
    .table-class .card .card-body .vbt-table-wrapper table thead .vbt-column-header:last-child{

        border-right: none !important;
    }
    .table-class .card .card-body .vbt-table-wrapper table thead .vbt-column-header {
    color : #ffffff;
        font-weight: 400;
    background-color: #133042;

    }

    .container-custom .container .top-class .table-class .card .card-header{
        border-top-right-radius: 25px;
        border-bottom-right-radius: 25px;
        width: 30%;
        }
    @media screen and (max-width: 480px) {
        .container-custom .container .top-class .table-class .card .card-header{
            width : 60% !important;
        }

        /*margin-top: 15px;*/
    }
    .container-custom .container .top-class .table-class .card{
        border-top: 1px solid white;
        top: 15px;
        position: relative;
    }
    .container-custom .container .top-class .table-class .card, .container-custom .container .top-class .table-class .card .card-body{
        border-top:1px solid white;

    }
    .pagination .input-group input{
        font-size: 11px;
        height: 38px;
    }

    /*scoped*/
    .count-btn-custom{
        background: white;
        color: black !important;
        border: 1px solid #59B6DB;
        padding: 3px;
    }
    .count-btn-custom:hover{ background: #59B6DB52}
    .container-relative{
        position: relative;
    }
    @media screen and (max-width:390px){
        .container-relative{
            padding: 0;
            }
        }
    .container-custom{
        box-shadow: 0px 0px 8px 0px;
        padding-bottom: 2em;

    }
    .refresh-button{
        position: absolute;
        top: 1.5em;
        background-color: #2c3e50;
        right: 36px;
        z-index: 99;
    }
    .graph-icon{position: relative}
    .dot {
        height           : 20px;
        width            : 20px;
        background-color : firebrick;
        border-radius    : 50%;
        display          : inline-block;
        position         : absolute;
        z-index          : 99;
        top              : -8px;
        font-size        : 12px;
        /*left: 1px;*/
        /*padding ;-right: 5%;*/
        /*right: 11%;*/
        }
    .table-class. card .card-header .card-body .table-responsive .table>tbody>tr:nth-child(even)>td {
        background-color: #FEF1DE !important;
        }
    .table-class .card .card-header {
        border-bottom: 3px solid #28b6ec;
        }
    .resultBtn{
        background : #28b6ec;
        }
    .top-class .table-class .card .card-body{
        padding-right: 0px;
        padding-left: 0px;
        }
</style>
