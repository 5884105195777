import Vue from 'vue'
import VueRouter from 'vue-router'
import CreateUserStrategy from '../views/user-strategies/create.vue'
import UserStrategies from '../views/user-strategies/index.vue'
import StrategiesComparison from '../views/comparion/strategies.vue'
import StrategyGraph from '../views/user-strategies/graph.vue'
import ComparisonResult from '../views/comparion/results.vue'
import Login from'../views/Login'
import CustomerLogin from'../views/CustomerLogin'
import Logout from'../views/Logout'
import Register from'../views/Register'
import Email from '../views/Email'
import CustomerPanel from '../views/CustomerPanel'
import CustomLogin from'../views/customLogin'
import CustomRegister from'../views/customRegister'
import Profile from '../views/profile/index'
import ProfileEdit from '../views/profile/edit'
import PasswordReset from'../views/ResetPassword'
import ResetPasswordConfirmation from'../views/ResetPasswordConfirmation'
import Dashboard from '../views/Dashboard'
import Support from "../views/Support";
import Welcome from "../views/Welcome";

import EqualWeight from "../views/EqualWeight";
import FlexibleWeight from "../views/FlexibleWeight";
import RiskParity from "../views/RiskParity";
import TrendFollowing from "../views/TrendFollowing";
import Momentum from "../views/Momentum";
import VolatilityTargeting from "../views/VolatilityControl";

Vue.use(VueRouter)

const routes = [
  { path: '/', name: 'home', component: UserStrategies, meta: { requiresAuth: true, layout: 'auth-layout', heading: ''}},
  { path: '/welcome', name: 'welcome', component: Welcome, meta: { requiresAuth: true, layout: 'auth-layout', heading: 'Welcome'}},
  { path: '/equal-weight', name: 'equal-weight', component: EqualWeight, meta: { requiresAuth: true, layout: 'auth-layout', heading: 'Equal Weight'}},
  { path: '/flexible-weight', name: 'flexible-weight', component: FlexibleWeight, meta: { requiresAuth: true, layout: 'auth-layout', heading: 'Flexible Weight'}},
  { path: '/risk-parity', name: 'risk-parity', component: RiskParity, meta: { requiresAuth: true, layout: 'auth-layout', heading: 'Risk Parity'}},
  { path: '/trend-following', name: 'trend-following', component: TrendFollowing, meta: { requiresAuth: true, layout: 'auth-layout', heading: 'Trend Following'}},
  { path: '/momentum', name: 'momentum', component: Momentum, meta: { requiresAuth: true, layout: 'auth-layout', heading: 'Momentum'}},
  { path: '/volatility-targeting', name: 'volatility-targeting', component: VolatilityTargeting, meta: { requiresAuth: true, layout: 'auth-layout', heading: 'Volatility Targeting'}},
  { path: '/help', name: 'help', component: Welcome, meta: { requiresAuth: true, layout: 'auth-layout', heading: 'Help'} , query: { help: true } },
	{ path: '/cpanel', name: 'customer-panel', component: CustomerPanel, meta: { requiresAuth: false, layout: 'guest-layout', heading: ''}},
  { path: '/login', name: 'login', component: Login, meta: { requiresAuth: false, layout: 'guest-layout', heading: 'Login'}},
  { path: '/logout', name: 'logout', component: Logout, meta: { requiresAuth: true, layout: 'auth-layout', heading: ''}},
	{ path: '/register', name: 'register', component: Register, meta: { requiresAuth: false, layout: 'guest-layout', heading: 'Register'}},
	{ path: '/email', name: 'email', component: Email, meta: { requiresAuth: false, layout: 'guest-layout', heading: ''}},

  { path: '/user-strategies/create', name: 'user-strategy-create', component: CreateUserStrategy, meta: { requiresAuth: true, layout: 'auth-layout', heading: 'Create Strategy'}},
  { path: '/user-strategies', name: 'user-strategies', component: UserStrategies, meta: { requiresAuth: true, layout: 'auth-layout', heading: 'Your Strategies'}},
  { path: '/user-strategies/:id/graph', name: 'user-strategy-graph', component: StrategyGraph, meta: { requiresAuth: true, layout: 'auth-layout', heading: 'Strategy Results'}},
	{ path: '/comparison/strategies', name: 'comparison-strategies', component: StrategiesComparison, meta: { requiresAuth: true, layout: 'auth-layout', heading: 'Comparison'}},
	{ path: '/comparison/result', name: 'comparison-result', component: ComparisonResult, meta: { requiresAuth: true, layout: 'auth-layout', heading: 'Comparison Results'}},

	// { path: '/dashboard', name: 'dashbaord', component: Dashboard,   },
	// { path: '/customer/login', name: 'customerLogin', component: CustomerLogin,   },
	{ path: '/dashboard', name: 'dashboard', component: Dashboard, meta: { requiresAuth: true, layout: 'auth-layout', heading: 'Dashboard'}},
	// { path: '/custom/login', name: 'custom-login', component: CustomLogin, meta: { requiresAuth: false, layout: 'guest-layout', heading: ''}},
	// { path: '/custom/register', name: 'custom-register', component: CustomRegister, meta: { requiresAuth: false, layout: 'guest-layout', heading: ''}},
	{ path: '/profile', name: 'profile', component: Profile, meta: { requiresAuth: true, layout: 'auth-layout', heading: 'Profile'}},
	{ path: '/profile/edit', name: 'profile-edit', component: ProfileEdit, meta: { requiresAuth: true, layout: 'auth-layout', heading: 'Profile - Edit'}},
	{ path: '/password-reset', name: 'password-reset', component: PasswordReset, meta: { requiresAuth: false, layout: 'guest-layout', heading: 'Password Reset'}},
	{ path: '/password-reset-confirmation/:token', name: 'password-reset-confirmation', component: ResetPasswordConfirmation, meta: { requiresAuth: false, layout: 'guest-layout', heading: 'Password Reset Confirmation'}},
	{ path: '/support', name: 'support', component: Support, meta: { requiresAuth: true, layout: 'auth-layout', heading: 'Support'}},
]

const router = new VueRouter({
  // mode: 'history',
  // base: '/account',
  routes
})

export default router
