//more spinners
//https://nguyenvanduocit.github.io/vue-loading-spinner/
import '../css/vue-spinner.scss'
import Vue from 'vue'
// import {RotateSquare} from 'vue-loading-spinner'
// import {RotateSquare2} from 'vue-loading-spinner'
// import {RotateSquare3} from 'vue-loading-spinner'
// import {RotateSquare4} from 'vue-loading-spinner'
// import {RotateSquare5} from 'vue-loading-spinner'
// import {Circle} from 'vue-loading-spinner'
import {Circle2} from 'vue-loading-spinner'
import {Circle3} from 'vue-loading-spinner'
import {Circle4} from 'vue-loading-spinner'
import {Circle5} from 'vue-loading-spinner'
import {Circle6} from 'vue-loading-spinner'
import {Circle7} from 'vue-loading-spinner'
import {Circle8} from 'vue-loading-spinner'
import {Circle9} from 'vue-loading-spinner'
import {Circle10} from 'vue-loading-spinner'
import {Circle11} from 'vue-loading-spinner'
import {Mikepad} from 'vue-loading-spinner'

// Vue.component('RotateSquare', RotateSquare)
// Vue.component('RotateSquare2', RotateSquare2)
// Vue.component('RotateSquare3', RotateSquare3)
// Vue.component('RotateSquare4', RotateSquare4)
// Vue.component('RotateSquare5', RotateSquare5)
// Vue.component('Circle', Circle)
Vue.component('Circle2', Circle2)
Vue.component('Circle3', Circle3)
Vue.component('Circle4', Circle4)
Vue.component('Circle5', Circle5)
Vue.component('Circle6', Circle6)
Vue.component('Circle7', Circle7)
Vue.component('Circle8', Circle8)
Vue.component('Circle9', Circle9)
Vue.component('Circle10', Circle10)
Vue.component('Circle11', Circle11)
Vue.component('Mikepad', Mikepad)

