<template >
	<div class="container container-custom ">
		<div class="container container-relative">
			<div class="row justify-content-center ">
				<div class="col-md-12 padding-rightClass">
					<div class="card mt-3em-b-1-w custom-padding-bottom">

						<b-modal hide-header-close id="deletemodal" class="delete-modal"  title="Account Deletion Confirmation">
<!--							<p class="lead">Hello From Modal!</p>-->
<!--							<p>I have button(s): {{ selected }}</p>-->
							<br >
							<p class="text-center">Are You Sure you went to delete your Account ?</p><br >
							<button class="ml-3 btn btn-danger float-right profile-delete" @click="destroy">Yes, Delete</button>

							<b-btn >
								No, Cancel
							</b-btn>
							<template slot="modal-footer">

							</template>
						</b-modal>

						<div class=" heading-color col-md-6 col-sm-12 col-lg-3 col-xs-12 card-custom-create user-strategy">Profile</div>
						<div class="profile-buttons">
						<router-link  to= "/profile/edit" class="btn ml-3 float-right profile-edit">Edit</router-link>
						<button class="ml-3 btn btn-danger float-right profile-delete"  v-b-modal="'deletemodal'">Delete Account</button>
<!--						<button class="ml-3 btn btn-danger float-right profile-delete"  @click="destroy">Delete Account</button>-->
						</div>
							<div class="card-body card-body-custom">
							<div class="row">
								<div class="col-md-3"></div>

					<div class="col-md-6">
						<div class="col-sm-12">
							<div class="form-group">
								<label>Email</label >
								<label class="form-control overflow-hidden">{{profile.email}}</label >
							</div>
						</div>
						<div class="col-sm-12">
								<div class="form-group">
									<label >Name</label >
									<label class="form-control overflow-hidden">{{profile.name}}</label >
								</div>
							</div>
						<div class="col-sm-12">
								<div class="form-group">
									<label>Phone Number</label >
									<label class="form-control overflow-hidden">{{profile.phoneNo}}</label >
								</div>
							</div>
					</div>
					<div class="col-md-3"></div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template >
<script >
	import { mapGetters } from 'vuex'
	import _ from 'lodash'

	export default {
		name: "profile",
		computed: {
			...mapGetters({
				              profile: 'Auth/profile',
			              }),
		},
		methods: {
			fetchSetups () {
				if(_.isEmpty(this.profile)) {
					this.$store.dispatch('Auth/fetch')
				}
			},
			destroy () {
				if(! _.isEmpty(this.profile)) {
					this.$store.dispatch('Auth/destroy', this.profile.id)
					    .then(res => {
					    	console.log('result',res.status)
						    if (res.status === 200) {
						    	console.log('status')
							    this.$router.push('/logout')
							    this.$toastr.s(res.message, 'Success')

						    }
					    }).catch(err => {
						this.isShow = true
						if(err.response.status === 422){
							console.log('422');

							_.forEach(err.response.data.message, error => {
								this.$toastr.e(error, 'Error')
							})
						}else {
							console.log('company');

							// this.$toastr.e('Something went wrong, please try again.', 'Error')
							this.$toastr.e('Your account Has been deleted.', 'Error')
						}
					})

				}
			},
			sessionValidate () {
				if(!this.$auth.isAuthenticated()){
					this.$router.push('/login')
				}
			}
		},
		mounted () {
			this.sessionValidate()
			this.fetchSetups()
		}
	}
</script >
<style >
	.modal-content .modal-header .modal-title{
		padding-left: 0px !important;
		}
</style >
<style scoped >

	.btn-danger{
background-color:#DC3545;
		}
	.profile-buttons{
		margin-top: -49px;
		}
	.profile-edit{
		padding-right: 52px;
		padding-left: 53px;
		}
	@media screen and (max-width: 823px) {
		.profile-buttons{
			margin-top: 15px;
			}
		}
	@media screen and (max-width: 505px) {
		.profile-buttons{
			text-align: center !important;
			}
		.profile-delete{
			margin-top: 15px;
			float: none !important;
			}
		.profile-edit{
			float: none !important;
			}
		}
	.profile-delete:hover{
		background-color: #59B6DB;
		}
	.custom-padding-bottom{
		padding-bottom: 10%;
		}
</style >