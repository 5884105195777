<template >
	<div class="sidebar-class sidebar-width responsive">

		<div class="sidebar" id="collapseExample">
			<nav class="sidebar-nav mt-5">
				<ul class="nav" id="myTab">
					<li class="main-logo ">
						<router-link  to= "/welcome" class="nav-link">	<img src = "../assets/images/logo.png" class="mb-5 ml-3 pl-1" width="80%"></a >
						</router-link>
					</li>
					<li class="nav-item assign-permissions permissions-sidebar w-100">
						<router-link  to= "/dashboard" class="nav-link">
							<i class="fas fa-columns sidebar-icon icon-width"></i>Dashboard
						</router-link>
					</li>
					<li class="nav-item assign-permissions permissions-sidebar w-100">
						<router-link  to= "/user-strategies/create" class="nav-link">
							<i class="fas fa-lightbulb sidebar-icon"></i>&nbsp &nbsp Create Strategy
						</router-link>
					</li>
					<li class="nav-item user-strategies-sidebar w-100">
						<router-link  to= "/user-strategies" class="nav-link">
							<i class="fas fa-desktop sidebar-icon"></i>Your Strategies
						</router-link>
					</li>

					<li class="nav-item user-strategies-sidebar w-100">
						<router-link  to= "/comparison/strategies" class="nav-link">
							<i class="fas fa-chart-pie sidebar-icon"></i>Comparison
						</router-link>
					</li>
					<li class="nav-item user-strategies-sidebar w-100">
						<router-link  to= "/profile" class="nav-link">
							<i class="fas fa-user-alt sidebar-icon"></i>Profile
						</router-link>
					</li>
					<li class="nav-item user-strategies-sidebar w-100">
						<router-link  to= "/Support" class="nav-link">
							<i class="fas fa-user-alt sidebar-icon"></i>Support
							<small class="text-danger" v-if="newMessages > 0">New</small>
						</router-link>
					</li>
					<span class="mt-5 w-100 pt-3 pb-3 pr-1">
            <li class="nav-item user-strategies-sidebar w-100">
						<router-link  to= "/logout" class="nav-link">
							<i class="fas fa-sign-out-alt sidebar-icon"></i>Logout
						</router-link>
            </li>
          </span>
				</ul>
			</nav>
		</div>
	</div>
</template >
<script >
	import {mapGetters} from 'vuex'
	import _ from 'lodash'
	export default {
		name: "side-bar",
		data () {
			return {
				timer: '',
			}
		},
		watch: {
			timer() {
				let timer = JSON.parse(localStorage.getItem(process.env.VUE_APP_LOCAL_STORAGE_CHAT_INTERVAL_ID_LABEL))
				localStorage.setItem(process.env.VUE_APP_LOCAL_STORAGE_CHAT_INTERVAL_ID_LABEL, this.timer)
			}
		},
		computed: {
			...mapGetters({
				              chats: 'Chat/get',
				              newMessages: 'Chat/unReadSupportMessagesCount',
			              }),
		},
		methods: {
			fetchSetups () {
				if (this.chats.length === 0) {
					this.refresh()
				}
			},
			setInterval() {
				this.refresh()
				let timer
				if(this.timer.length === 0) {
					window.intervalTimer = timer = setInterval(() => {
						this.refresh()
					}, 10 * 60000)
					this.timer= timer
				}
			},
			refresh () {
				if(this.$auth.isAuthenticated()) {
					this.$store.dispatch('Chat/fetch')
				}
			},
		},
		async mounted () {
			await this.fetchSetups(  )
			this.setInterval()
		}


	}
</script >
<style >

	.main-row{
		width : 100%  ;
		}
	.sidebar-class{
		background-color: #132e41;
		min-height: 591px;
		height: auto !important;

		border-right: 8px solid #59B6DB;
		padding-right: 0;
		}
	.dashboard-heading{
		padding-top: 10px;
		padding-bottom: 8px;
		background-color: #132e41;
		color: #59B6DB;
		text-align: center;
		justify-content: center;
		font-weight: bold;
		margin-right: 5% ;
		margin-left: 4% ;
		border-bottom: 7px solid #59B6DB;
		border-radius: 0px 0px 10px 10px ;
		}
	.nav-link{
		color : #ffffff;
		width: 100%;
		}
	.nav-item{
		margin-left: 4%;
		margin-bottom: 3%;
		}
	.nav-item:hover{
		background-color: #59B6DB;
		}
	.nav-link:hover{
		color: black;
		}
	.user-open{
		display: none;
		}
	a.menu_links { cursor: pointer; }
	.user-open{
		display: none;
		}
	a.menu_links { cursor: pointer; }
	.dash-cards{
		background-color: #EEF6F9 !important;
		}
	.sidebar-icon{
		margin-right: 0.8rem;
		font-size: 1.4rem;
		}
	.content-width{
		width : 80%;
		margin-left: 2%;
		margin-right: 1%;
		}
	.sidebar-width{
		width : 17%;

		}
	@media screen and (max-width: 1115px) {
		.content-width{
			width : 77%;
			margin-left: 2%;
			margin-right: 1%;
			}
		.sidebar-width{
			width : 20%;

			}
		}
	@media screen and (max-width: 992px) {
		.content-width{
			width : 73%;
			margin-left: 2%;
			margin-right: 1%;
			}
		.sidebar-width{
			width : 23%;

			}
		}
	@media screen and (max-width: 822px) {
		.content-width{
			width : 70% ;
			}
		.sidebar-width{
			width : 25% ;

			}
		}
	@media screen and (max-width: 762px) {
		.sidebar-icon{
			font-size: medium;
			}
		.content-width{
			width : 68%;

			}
		.sidebar-width{
			width : 27%;

			}
		}
	@media screen and (max-width: 740px) {
		.sidebar-icon{
			font-size: small;
			}
		.content-width{
			width : 68%;

			}
		.sidebar-width{
			width : 28%;

			}
		}
	@media screen and (max-width: 740px) {
		.sidebar-icon{
			font-size: small;
			}
			.nav-item{
				font-size: 80%
				}
		}


	@media screen and (max-width: 570px) {
		.sidebar-icon{
			font-size: small;
			}
		.content-width{
			width : 68%;

			}
		.sidebar-width{
			width : 29%;

			}
		}
	@media screen and (max-width: 550px) {
		.sidebar-icon{
			font-size: small;
			}
		.content-width{
			width : 65%;

			}
		.sidebar-width{
			width : 32%;

			}
		}
	@media screen and (max-width: 550px) {
		.sidebar-icon{
			font-size: x-small;
			}
		}
	@media screen and (max-width: 490px) {
		.content-width{
			width : 63%;
			}
		.sidebar-width{
			width : 34%;

			}
		}
	@media screen and (max-width: 460px) {
		.sidebar-icon{
			font-size:xx-small;
			}
		}
	@media screen and (max-width: 410px) {
		.sidebar-icon{
			display: none;
			}
		}
	@media screen and (max-width: 410px) {
		.nav-link{
				padding-right: 0
			}
		}
	@media screen and (max-width: 338px) {
		.nav-item{
			font-size: 70%
			}
		}

	/*@media screen and (max-width: 580px) {*/
	/*	.sidebar-icon{*/
	/*		font-size: small;*/
	/*		}*/
	/*	.nav-item{*/
	/*		font-size: 80%*/
	/*		}*/

	/*	}*/
	/*@media screen and (max-width: 522px) {*/
	/*	.sidebar-icon{*/
	/*		font-size: small;*/
	/*		}*/

	/*	.content-width{*/
	/*		width : 62%;*/

	/*		}*/
	/*	.sidebar-width{*/
	/*		width : 33%;*/

	/*		}*/
	/*	}*/
	/*@media screen and (max-width: 488px) {*/

	/*	.content-width{*/
	/*		width : 57%;*/

	/*		}*/
	/*	.sidebar-width{*/
	/*		width : 36%;*/

	/*		}*/
	/*	}*/
	/*@media screen and (max-width: 474px) {*/
	/*	.content-width{*/
	/*		width : 52%;*/
	/*		}*/
	/*	.sidebar-width{*/
	/*		width : 43%;*/

	/*		}*/
	/*	}*/
	/*@media screen and (max-width: 425px) {*/
	/*	.sidebar-icon{*/
	/*		display : none;*/
	/*		}*/
	/*	.dashboard-heading{*/
	/*		font-size: 30px !important;*/
	/*		}*/
	/*	}*/
	/*@media screen and (max-width: 357px) {*/
	/*	.nav-item{*/
	/*		font-size: 80%*/
	/*		}*/
	/*	}*/
	/*@media screen and (max-width: 322px) {*/
	/*	.dashboard-heading{*/
	/*		font-size: 25px !important;*/
	/*		}*/
	/*	.nav-item{*/
	/*		font-size: 65%*/
	/*		}*/
	/*	}*/
	/*@media screen and (max-width: 265px) {*/
	/*	.dashboard-heading{*/
	/*		font-size: 20px !important;*/
	/*		}*/
	/*	.nav-item{*/
	/*		font-size: 60%*/
	/*		}*/
	/*	}*/

</style>
