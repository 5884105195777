<template>
    <div class="row">
        <div class="col-sm-4">
            <div class="card user-card dash-cards card-border border-dark">
              <div class="card-body">
                <h4  class=" mb-2 dash-color text-dark">Total Strategies </h4>
	              <hr class="hr-border border-dark">
	              <p class="float-left p-class">{{totalStrategies('USD')}}<span class="small">-USD</span></p>
	              <p class="p-class float-right">{{totalStrategies('GBP')}}<span class="small">-GBP</span></p>
              </div>
            </div>
        </div>
        <div class="col-sm-4 mb-4" v-for="strategy in strategies">
            <div class="card user-card dash-cards card-border" :class="'border-'+strategy.colorCode">
              <div class="card-body " >
                <h4  class=" mb-2 dash-color" :class="'text-'+ strategy.colorCode">{{strategy.name}}</h4>
	              <hr class="hr-border" :class="'border-'+ strategy.colorCode">
	              <p class="float-left p-class">{{countsOf(strategy.id, 'USD')}}<span class="small">-USD</span></p>
	              <p class="p-class float-right">{{countsOf(strategy.id, 'GBP')}}<span class="small">-GBP</span></p>
	              <!--<p class="float-left p-class">{{countsOf(strategy.id)}}</p>-->
              </div>
            </div>
        </div>
</div>
</template>

<script>
	import {mapGetters} from 'vuex'
	import _ from 'lodash'
	export default {
		name: 'dashboard',
		components: {
		},
		computed: {
			...mapGetters({
				              strategies: 'strategy/get',
				              userStrategies: 'UserStrategy/get',
			              }),
		},
		methods: {
			totalStrategies(currency) {
				return _.chain(this.userStrategies).filter(userStrategy => {
					return userStrategy.currency === currency
				}).values().size()
			},
			countsOf(strategyId, currency) {
				return _.chain(this.userStrategies)
					.filter(userStrategy => {
						return userStrategy.strategy.id === strategyId &&
						       userStrategy.currency === currency
					}).size()
			},
			validateSession() {
				if (!this.$auth.isAuthenticated()) {
					this.$auth.logout()
				}
			},
			fetchSetups () {
				if(this.strategies.length === 0) {
					this.$store.dispatch('strategy/fetch')
				}
				if(this.userStrategies.length === 0) {
					this.$store.dispatch('UserStrategy/fetch')
				}
			},
		},
		mounted () {
			this.validateSession()
			this.fetchSetups()

		},
	}
</script>

<style scoped>
	.card-border{
		border-top :4px solid;
		border-right: 0px;
		border-left: 0px;
		border-bottom: 0px;
		}

	.hr-border{
		border: 1px solid #959B9C;
		}
	.ew-class{
		color: #70AB6A;
		}
	.tf-class{
		color: #D9A96D;
		}
	.rp-class{
		color: #313B41;
		}
	.p-class{
		font-size: xx-large;
		margin-bottom: 0;
		}
	.user-card> .e-class{
		border-top: 3px solid #70AB6A;
		}
	.user-card> .t-class{
		border-top: 3px solid #D9A96D;
		}
	.user-card> .r-class{
		border-top: 3px solid #313B41;
		}
</style>
