import getters from './getters'
import mutations from './mutations'
import actions from './actions'

export const Chat = {
  namespaced: true,
  state: {
    conversations: []
  },
  getters,
  mutations,
  actions
};
