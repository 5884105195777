import _ from 'lodash'
import {Strategy} from "../../enums/Strategy";
const IMPLEMENTED_STRATEGIES = [Strategy.MOMENTUM, Strategy.EQUAL_WEIGHT,
                                Strategy.TREND_FOLLOWING, Strategy.RISK_PARITY,
                                Strategy.VOL_TARGET_FILTER, Strategy.FLEXIBLE_WEIGHTS_YOU_CHOOSE ]
export default {
  get: state => {
    return _.filter(state.strategies, strategy => {
      return _.includes(IMPLEMENTED_STRATEGIES, strategy.name.toLowerCase())
    })
  },
	find: state => {
		return id => {
			return _.find(state.strategies, strategy => {
				return parseInt(strategy.id) === parseInt(id)
			})
		}
	},
  lists: state => {
    return _.chain(state.strategies)
            .filter(strategy => {
              return _.includes(IMPLEMENTED_STRATEGIES, strategy.name.toLowerCase())
            }).map(strategy => {
              return {
                value: strategy.id,
                label: strategy.name
              }
            }).value()
  },
}
