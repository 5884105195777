<template>
    <div >
    <div class="sidebar-class float-right sidebar-width responsive">
        <div class="sidebar" id="collapseExample">
            <nav class="sidebar-nav mt-5 mb-5">
                <ul class="nav" id="myTab">

                    <li v-if="pageFrom==='welcome'" class="nav-item assign-permissions permissions-sidebar w-100" :class="{'nav-item-active': page==='welcome'}">
                        <router-link class="nav-link" to="/welcome" >Welcome</router-link>
                    </li>

                    <li class="nav-item assign-permissions permissions-sidebar w-100" :class="{'nav-item-active': page==='equal weight'}">
                        <router-link class="nav-link" to="/equal-weight">Equal Weight</router-link>
                    </li>
                    <li class="nav-item assign-permissions permissions-sidebar w-100" :class="{'nav-item-active': page==='flexible weights - you choose'}">
                        <router-link class="nav-link" to="/flexible-weight">Flexible Weight</router-link>
                    </li>
                    <li class="nav-item assign-permissions permissions-sidebar w-100" :class="{'nav-item-active': page==='risk parity'}">
                        <router-link class="nav-link" to="/risk-parity">Risk Parity</router-link>
                    </li>
                    <li class="nav-item assign-permissions permissions-sidebar w-100 " :class="{'nav-item-active': page==='trend following'}">
                        <router-link class="nav-link" to="/trend-following">Trend Following</router-link>
                    </li>
                    <li class="nav-item user-strategies-sidebar w-100" :class="{'nav-item-active': page==='momentum'}">
                        <router-link class="nav-link" to="/momentum">Momentum</router-link>
                    </li>
                    <li class="nav-item user-strategies-sidebar w-100" :class="{'nav-item-active': page==='volatility targeting'}">
                        <router-link class="nav-link" to="/volatility-targeting">Volatility Targeting</router-link>
                    </li>

                </ul>
            </nav>
    </div>
    </div>
    </div>
</template >
<script >
	import { mapGetters } from 'vuex'

    export default {
        name: "SideSubMenu",
        props:['page'],
        data () {
	        return {

		        view: '',

	        }
        },
	    computed: {
		    ...mapGetters({
			                  pageFrom: 'SiteParams/get',
		                  }),

	    },
    }
</script >

<style scoped >
    .nav-item
    { cursor: pointer; }
    .content{
        padding: 5%;
        justify-content: center;
        text-align:justify;
        }
    .main-row{
        width : 100%  ;
        }
    .sidebar-class{
        background-color: #132e41;
        min-height: 225px;
        height: auto !important;
        /*text-align:center ;*/
        border-left: 8px solid #59B6DB;
        border-right: 0px solid #59B6DB;
        padding-right: 0;
        }
    .dashboard-heading{
        padding-top: 10px;
        padding-bottom: 8px;
        background-color: #132e41;
        color: #59B6DB;
        text-align: center;
        justify-content: center;
        font-weight: bold;
        margin-right: 5% ;
        margin-left: 4% ;
        border-bottom: 7px solid #59B6DB;
        border-radius: 0px 0px 10px 10px ;
        }
    .nav-link{
        color : #ffffff;
        width: 100%;
        }
    .nav-item{
        margin-left: 0% !important;
        margin-bottom: 0% !important;
        }
    .nav-item:hover{
        background-color: #59B6DB;
        }
    .nav-item-active{
	    background-color: #59B6DB;
	    }
    .nav-link:hover{
        color: black;
        }
    .user-open{
        display: none;
        }
    a.menu_links { cursor: pointer; }
    .user-open{
        display: none;
        }
    a.menu_links { cursor: pointer; }
    .dash-cards{
        background-color: #EEF6F9 !important;
        }
    .sidebar-icon{
        margin-right: 0.8rem;
        font-size: 1.4rem;
        }
    .content-width{
        width : 80%;
        margin-left: 2%;
        margin-right: 1%;
        }
    .sidebar-width{
        width : 17%;

        }
    @media screen and (max-width: 1115px) {
        .content-width{
            width : 77%;
            margin-left: 2%;
            margin-right: 1%;
            }
        .sidebar-width{
            width : 20%;

            }
        }
    @media screen and (max-width: 992px) {
        .content-width{
            width : 73%;
            margin-left: 2%;
            margin-right: 1%;
            }
        .sidebar-width{
            width : 23%;

            }
        }
    @media screen and (max-width: 822px) {
        .content-width{
            width : 70% ;
            }
        .sidebar-width{
            width : 25% ;

            }
        }
    @media screen and (max-width: 762px) {
        .sidebar-icon{
            font-size: medium;
            }
        .content-width{
            width : 68%;

            }
        .sidebar-width{
            width : 27%;

            }
        }
    @media screen and (max-width: 740px) {
        .sidebar-icon{
            font-size: small;
            }
        .content-width{
            width : 68%;

            }
        .sidebar-width{
            width : 28%;

            }
        }
    @media screen and (max-width: 740px) {
        .sidebar-icon{
            font-size: small;
            }
        .nav-item{
            font-size: 80%
            }
        }


    @media screen and (max-width: 570px) {
        .sidebar-icon{
            font-size: small;
            }
        .content-width{
            width : 68%;

            }
        .sidebar-width{
            width : 29%;

            }
        }
    @media screen and (max-width: 550px) {
        .sidebar-icon{
            font-size: small;
            }
        .content-width{
            width : 65%;

            }
        .sidebar-width{
            width : 32%;

            }
        }
    @media screen and (max-width: 550px) {
        .sidebar-icon{
            font-size: x-small;
            }
        }
    @media screen and (max-width: 490px) {
        .content-width{
            width : 63%;
            }
        .sidebar-width{
            width : 34%;

            }
        }
    @media screen and (max-width: 460px) {
        .sidebar-icon{
            font-size:xx-small;
            }
        }
    @media screen and (max-width: 410px) {
        .sidebar-icon{
            display: none;
            }
        }
    @media screen and (max-width: 410px) {
        .nav-link{
            padding-right: 0
            }
        }
    @media screen and (max-width: 338px) {
        .nav-item{
            font-size: 70%
            }
        }

    /*@media screen and (max-width: 580px) {*/
    /*	.sidebar-icon{*/
    /*		font-size: small;*/
    /*		}*/
    /*	.nav-item{*/
    /*		font-size: 80%*/
    /*		}*/

    /*	}*/
    /*@media screen and (max-width: 522px) {*/
    /*	.sidebar-icon{*/
    /*		font-size: small;*/
    /*		}*/

    /*	.content-width{*/
    /*		width : 62%;*/

    /*		}*/
    /*	.sidebar-width{*/
    /*		width : 33%;*/

    /*		}*/
    /*	}*/
    /*@media screen and (max-width: 488px) {*/

    /*	.content-width{*/
    /*		width : 57%;*/

    /*		}*/
    /*	.sidebar-width{*/
    /*		width : 36%;*/

    /*		}*/
    /*	}*/
    /*@media screen and (max-width: 474px) {*/
    /*	.content-width{*/
    /*		width : 52%;*/
    /*		}*/
    /*	.sidebar-width{*/
    /*		width : 43%;*/

    /*		}*/
    /*	}*/
    /*@media screen and (max-width: 425px) {*/
    /*	.sidebar-icon{*/
    /*		display : none;*/
    /*		}*/
    /*	.dashboard-heading{*/
    /*		font-size: 30px !important;*/
    /*		}*/
    /*	}*/
    /*@media screen and (max-width: 357px) {*/
    /*	.nav-item{*/
    /*		font-size: 80%*/
    /*		}*/
    /*	}*/
    /*@media screen and (max-width: 322px) {*/
    /*	.dashboard-heading{*/
    /*		font-size: 25px !important;*/
    /*		}*/
    /*	.nav-item{*/
    /*		font-size: 65%*/
    /*		}*/
    /*	}*/
    /*@media screen and (max-width: 265px) {*/
    /*	.dashboard-heading{*/
    /*		font-size: 20px !important;*/
    /*		}*/
    /*	.nav-item{*/
    /*		font-size: 60%*/
    /*		}*/
    /*	}*/

</style>
