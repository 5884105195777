<template >
	<div class="container">
	<div class="row p-5 login-row">
	<div class="col-md-6">
		<h2>About Uss</h2>
		<hr >
		<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. </p>
	</div>
		<div class="col-md-6">

			<h2 class="text-center login-class">Login</h2>
			<div class="card custom-login-card">
				<div class="card-body">
					<form action = "" >

					<div class="form-group">
						<label >Email</label >
						<input type = "email" class="form-control">
					</div>
					<div class="form-group">
						<label >Password</label >
						<input type = "password" class="form-control">
					</div>

						<div class="reg-btn">
						<button type="submit" class="btn btn-primary rounded-pill login-button">Login</button>
						<button type="submit" class="btn btn-dark register-button rounded-pill ml-4">Register</button>
						</div>
					</form >
				</div>
			</div>
		</div>
	</div>
	</div>
</template >
<script >
	export default {
		name: "customLogin"
	}
</script >
<style scoped >
	.login-class{
		background-color: #F99800;
		color: #ffffff;
		padding: 2px;
		margin-bottom: 5%;
		}
	.custom-login-card .card-body{
		background-color: #132E41;
		padding: 7%;
		color: white;
		}
	.register-button{
		background-color: #FC9301;
		color: white;
		text-align: center;
		padding-left: 7%;
		padding-right: 7%;
		align-content: center;
		}
	.login-button{
		text-align: center;
		padding-left: 7%;
		padding-right: 7%;
		}
	.reg-btn{
		text-align: center;
		}
	@media screen and (max-width: 560px){
		.login-row{
			padding: 0!important;
			}
		}
</style >
