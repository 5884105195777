<template>
  <div>
    <h2 class="text-center login-class pb-2 rounded-sm">Login</h2>
    <div class="card custom-login-card">
      <div class="card-body">
        <form action = "" @submit.prevent="login">

        <div class="form-group ">
                <label for="email">Email</label>
                  <input id="email" type="email" class="form-control" name="email" v-model="credentials.email"
                         required autocomplete="email" autofocus>
              </div>

              <div class="form-group ">
                <label for="password" >Password</label>

                  <input id="password" type="password" class="form-control " name="password" v-model="credentials.password" required autocomplete="current-password">
                </div>
               <div class="reg-btn">
                  <button type="submit" class="btn btn-primary rounded-pill login-button" v-if="isShow">Login</button>
                 <router-link  to= "register" class="btn btn-dark register-button rounded-pill ml-2">Register</router-link>
                  <circle2  v-if="!isShow" class="solent-spinner float-lg-left col-2 "></circle2>
                </div>
                <div class="text-center mt-4">
                 <router-link  to= "password-reset" class="rounded-pill">Forgot Password</router-link>
                </div>

            </form >
          </div>
        </div>
      </div>

</template>
<style scoped>
.solent-spinner{
  margin-left: 30%;
  }
    .login-class{
      background-color: #F99800;
      color: #ffffff;
      padding: 3px;
      margin-bottom: 5%;
      }
    .custom-login-card .card-body{
      background-color: #132E41;
      padding: 7%;
      color: white;
      }
    .register-button{
      background-color: #FC9301;
      color: white;
      text-align: center;
      padding-left: 7%;
      padding-right: 7%;
      align-content: center;
      }
    .login-button{
      text-align: center;
      padding-left: 7%;
      padding-right: 7%;
      }
    .reg-btn{
      text-align: center;
      }
    @media screen and (max-width: 560px){
      .login-row{
        padding: 0!important;
        }
      }


</style>
<script>
	import {mapGetters} from 'vuex'
	export default {
		data() {
			return {
				isShow: true,
				credentials: {
					email: '',
					password: ''
				},
			}
		},
		computed: {
			...mapGetters({
				              // isAuthenticated: 'Auth/isAuthenticated',
			              }),
		},
    methods: {
	    login () {
	    	this.isShow = false
				    this.$auth.login(this.credentials).then((res) => {
              if(res) {
                this.$router.push('/welcome')
              }
              this.isShow = true
				    }).catch(error => {
					    this.isShow = true
					    // this.$preLoader.hide()
					    if (error.code === 401) {
						    // if (error.message === 'The user credentials were incorrect.') {
						    // 	this.$toastr.e('Sorry, that email or password didn\'t work', 'Login Failed')
						    // } else {
							    this.$toastr.e(error.message,'Login Failed')
						    // }
					    } else {
						    this.$toastr.e('Please try again.', 'Login Failed')
					    }
				    })
	    },
      sessionValidate () {
	    	if(this.$auth.isAuthenticated()){
	    		this.$router.push('/user-strategies')
        }
      }
    },
    mounted() {
			this.sessionValidate()
    }

	}
</script>
