<template>
  <div>
    <SideSubMenu page="trend following"/>
<div class="content-page">
    <h4 class="top-heading mb-3">Trend Following</h4>

    <p><span class="welcome-internal-headings">Trend Following</span> is an investment strategy that aims to give investors exposure to the market when the market is in an uptrend, and aims to place
                                               their capital into a risk free asset class, like US Treasuries, when the market is trending down.  The Solent Investment Strategy Builder uses an academically-tested process to identify these up and down trends in each market.  The great news about this strategy is that it can be applied to just one market, or to several markets.  When applied to several markets the Solent Investment Strategy Builder allows you to choose the strategic weight that you want to give to each market.<p>
    <p>The Chart below shows how the Trend Signal works for a single market.  On the chart we have highlighted two periods.  In red is a period where the system would
   invest the capital assigned to this market into a risk free asset; in green is a period where the system would invest the capital assigned to this market into this market.  The system reviews the signal from each of the chosen markets at the end of each month and rebalances the strategy accordingly.
   </p>
   <img src = "../assets/images/graph.png" alt = "" width="70%" class="trend-following" ><br >
   <p>Click on the “Create Strategy” tab and then select “Trend Following” from the drop down menu.  Choose the sample period that you are interested in.  Then choose the markets that you are interested in gaining access to from the “Available Markets” drop down menu.  Once you have chosen the markets, click on the weight tab and either choose to equally weight your chosen markets, or customise the weights – but make sure your customised weights sum to 100%.</p>
   <p>Once you have chosen all of the markets that you would like to include in your strategy, and the weights, use the submit button at the bottom of the page and the <span class="welcome-internal-headings">Solent Investment
                                                                                                                                                                            Strategy Builder</span> will
                                                                                                                                                                                                                create your portfolio.  You can view and download the results of this strategy.  Notice how the use of Trend Following tends to lead to a low maximum drawdown. It’s that simple to use the <span class="welcome-internal-headings">Solent Investment Strategy Builder.</span></p>
  </div>
  </div>
</template>


<script >
  import SideSubMenu from '@/components/SideSubMenu.vue'
  import {Strategy} from "../enums/Strategy";

  export default {
    name: "trend-following",
    components: {
      SideSubMenu
    },
  }
</script >

<style scoped>

  .welcome-internal-headings{
    color: #59B6DB;
    font-weight: 500;
    font-style: italic;
    }
  .trend-following{
    margin-left: 13%;
    margin-bottom: 5%;
    margin-top: 2%;
    }
  .content-page {
    padding : 4%;
    width: 80%;
    justify-content : center;
    text-align:justify;

    }
  .top-heading{
    margin-top: -6%;
    }
  P{
    font-size: 17px;
    }
</style>

