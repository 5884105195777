import _ from 'lodash'

export default {
  get: state => {
    return state.marketRecords
  },
  lists: state => {
    return _.map(state.marketRecords, record => {
        return {
          value: record.id,
          label: record.name
        }
    })
  },
	// momentumStrategy: state =>  {
	// 	return _.map(state.marketRecords, market => {
	// 		return {
	// 			returnMonthDate: market.returnMonthDate,
	// 			returnMonth: market.returnMonth,
	// 			marketId: market.market.id,
	// 			marketName: market.market.name,
	// 			broadCategoryId: market.market.broadCategory.id,
	// 			broadCategoryName: market.market.broadCategory.name,
	// 			marketValue: market.marketValue,
	// 			marketDataCalculatedValue: this.getMarketCalculatedValue(market),
	// 			twelveMonthCalculatedValue: this.getTwelveMonthCalculatedValue(market),
	// 			rankCalculatedValue: this.getRankCalculatedValue(market),
	// 		}
	// 	})
	// },

}
