<template>
<div>
  <h2 class="text-center register-class pb-2 rounded-sm">REGISTER</h2>
  <div class="card register-card">
    <div class="card-body">
            <form action = "" @submit.prevent="register">
              <div class="form-group">
                <label for="name" >Full Name</label>

                  <input id="name" type="text" class="form-control " v-model="registration.name"
                         required autocomplete="name" autofocus>
                </div>

              <div class="form-group ">
                <label for="email" >Email</label>

                  <input id="email" type="email" class="form-control "
                         v-model="registration.email" required autocomplete="email">

                </div>

              <div class="form-group ">
                <label for="phone">Phone Number</label>

                  <input id="phone" type="number" class="form-control "
                         v-model="registration.phoneNumber"
                         required autocomplete="phone" autofocus>

              </div>

              <div class="form-group ">
                <label for="password" >Password</label>
                  <input id="password" type="password" class="form-control"
                         v-model="registration.password" required autocomplete="new-password">
                </div>

              <div class="form-group ">
                <label for="password-confirm">Confirm Password</label>

                  <input id="password-confirm" type="password" class="form-control"
                         v-model="registration.confirmPassword" required autocomplete="new-password">
                </div>


              <!--<div class="reg-btn">-->
                  <!--<button type="submit" class="btn btn-dark register-button" v-if="isShow">Register</button>-->
                <!--<circle2  v-if="!isShow" class="solent-spinner"></circle2>-->
                <!--<router-link  to= "login" class="btn btn-dark register-button rounded-pill ml-2">Login</router-link>-->
                <!--</div>-->

              <div class="reg-btn">
                <button type="submit" class="btn btn-dark rounded-pill register-button" >Register</button>
                <router-link  to= "login" class="btn btn-dark register-button rounded-pill ml-2">Login</router-link>
                <!--<circle2  v-if="!isShow" class="solent-spinner float-lg-left col-2 "></circle2>-->
              </div>

            </form>
          </div>
        </div>
</div>
</template>
<style scoped>

  .solent-spinner{
    margin-left: 25%;
    }
  .register-class{
    background-color: #F99800;
    color: #ffffff;
    padding: 2px;
    margin-bottom: 5%;
    }
  .register-card .card-body{
    background-color: #132E41;
    padding: 7%;
    color: white;
    }
  .register-button{
    background-color: #FC9301;
    color: white;
    border-radius: 18px;
    text-align: center;
    padding-left: 7%;
    padding-right: 7%;
    align-content: center;
    }
  .reg-btn{
    text-align: center;
    }
</style>
<script>
  export default {
    data() {
      return {
        isShow: true,
        registration: {
		      name: '',
		      email: '',
	        phoneNumber: '',
		      password: '',
		      confirmPassword: '',
	      },
      }
    },
    methods: {
	    register () {
        this.isShow = false
				    this.$auth.register(this.registration).then((response) => {

					    if (response.success) {
						       this.$toastr.s('You account is registered successfully and pending for approval.', 'Registration')
                   this.$router.push('/login');
						       this.isShow = false

					    //
						   //  this.$auth.login(this.registration).then((res) => {
							 //    if(res) {
								//     this.$router.push('/user-strategies');
							 //    }
               //    this.isShow = true
						   //  }).catch(error => {
               //    this.isShow = true
							 //    this.$toastr.e(error.message, 'Registration Failed')
						   //  })
					    }
				    }).catch(error => {
					    if (error.code === 422 && error.errors.email !== '') {
						    this.isShow = true

							    this.$toastr.e( 'Sorry, this email has already been taken','Registration Failed')
					    } else {
						    _.forEach(error.errors, error => {
							    this.$toastr.w(error,'Warning')
						    })
					    }
				    })
			    },
	    sessionValidate () {
		    if(this.$auth.isAuthenticated()){
			    this.$router.push('/user-strategies')
		    }
	    }
    },
    mounted () {
	    this.sessionValidate()

    }
  }
</script>