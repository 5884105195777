import router from "../router";

export default {
  prepareThen (response) {
    return {
      code: response.status,
      message: response.data.message,
      data: response.data.data
    }
  },
  prepareValidationCatch (error) {
    return {
      code: error.response.data.code,
      errors: error.response.data.errors
    }
  },
  prepareGeneralCatch (error) {
    return {
      code: error.response.status,
      message: error.response.data.message,
      success: error.response.data.success
    }
  },
	prepareCredentialFail (error) {
		return {
			code: 401,//error.response.status,
			message: error.response.data.message,
			success: false
		}
	},
	prepareCatch (error) {
    if (error.response.status === 422) {
      return this.prepareValidationCatch(error)
    } else if (error.response.status === 401) {
	    this.logout()
	    return this.prepareCredentialFail(error)
    } else if (error.response.status === 406) {
    	// Not handled yet
	    return this.prepareCredentialFail(error)
    } else {
      return this.prepareGeneralCatch(error)
    }
  },
	logout () {
		localStorage.removeItem(process.env.VUE_APP_LOCAL_STORAGE_TOKEN_LABEL)
		localStorage.removeItem(process.env.VUE_APP_LOCAL_STORAGE_EXPIRED_AT_LABEL)
		localStorage.removeItem(process.env.VUE_APP_LOCAL_STORAGE_IS_ADMIN_LABEL)
		let intervalId = localStorage.getItem(process.env.VUE_APP_LOCAL_STORAGE_INTERVAL_ID_LABEL)
		clearInterval(parseInt(intervalId))
		clearInterval(window.intervalTimer)
		// router.push('/login')

	}

}
