<template>
    <div>
        <div class="container container-custom pb-5">
            <div class="container container-relative">
                <div class="row justify-content-center">
                    <button title="Capture Result" class="btn btn-primary pull-right refresh-button refresh-button-compare capture-icon " @click="download"> <i class="fas fa-camera"></i></button>
                    <div class="col-md-12 pb-5">
                        <div class="card mt-3em-b-1-w" id="print-image">
                            <div class=" heading-color card-custom2">Cumulative Portfolio Result</div>

                        <div class="card-body">
                            <div class="row">
                                <div class="col-lg-7">
                                    <apexchart width="100%" height="407" type="line"
                                               :options="options" :series="series"></apexchart>
                                    <!-- Put canvas tag here. -->
                                </div>
                                <div class="col-lg-5">
                                    <p class="position-absolute font-weight-bold solent-watermark">SOLENT INVESTMENT STRATEGY BUILDER</p>
                                    <table class="table table-bordered">
                                        <tbody>
                                        <tr class="perform-stats-tr">
                                            <td colspan="2" class="text-center card-header heading-color performance-stats-class">Performance Statistics</td>
                                        </tr>
                                        <tr >
                                            <td colspan="2" class="text-center " >Strategy Name : {{userStrategy.name}}</td>
                                        </tr>
                                        <tr>
                                            <td class="text-left">Annualised Return</td>
                                            <td class="text-right">{{performanceStats.annualisedReturn ? performanceStats.annualisedReturn.toFixed(2) : 0}}%</td>
                                        </tr>
                                        <tr>
                                            <td class="text-left">Annualised Volatility</td>
                                            <td class="text-right">{{performanceStats.annualisedVolatility ? performanceStats.annualisedVolatility.toFixed(2) : 0}}%</td>
                                        </tr>
                                        <tr>
		                                        <td class="text-left">Sharp Ratio</td>
		                                        <td class="text-right" v-text="performanceStats.sharpRatio ? performanceStats.sharpRatio.toFixed(2) : 0"></td>
                                        </tr>
                                        <tr>
                                            <td class="text-left">Beta to US Equities</td>
                                            <td class="text-right" v-text="performanceStats.betaToUsEquity ? performanceStats.betaToUsEquity.toFixed(2) : 0"></td>
                                        </tr>
                                        <tr>
		                                        <td class="text-left">Maximum Drawdown</td>
		                                        <td class="text-right">
                                                <span v-if="performanceStats.maxDrawdown">
                                                    {{performanceStats.maxDrawdown ? performanceStats.maxDrawdown.toFixed(2) : 0}}%
                                                </span>
                                            </td>
	                                        </tr>
                                        <tr>
                                            <td class="text-left">Best Month</td>
                                            <td class="text-right">{{performanceStats.bestMonth ? performanceStats.bestMonth.toFixed(2) : 0}}%</td>
                                        </tr>
                                        <tr>
                                            <td class="text-left">Worst Month</td>
                                            <td class="text-right" >{{performanceStats.worstMonth ? performanceStats.worstMonth.toFixed(2) : 0}}%</td>
                                        </tr>
                                        <tr>
                                            <td class="text-left">Currency</td>
                                            <td class="text-right" >{{userStrategy.currency}}</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
                <div class = "row justify-content-center mt-3 mb-3" >
                    <div class = "col-sm-6">
                        <div class = "card" >
                					<b-button v-b-toggle.collapse-2 class = "p-0" >
                						<div class = "card-header" >
                							<h5 >Need Help ?</h5 >
                						</div >
                						<span class = "when-opened" >
                								<i class = "fa fa-chevron-down" aria-hidden = "true" ></i ></span > <span class = "when-closed" >

                               <i class = "fa fa-chevron-up" aria-hidden = "true" ></i ></span >
                					</b-button >
                					<b-collapse id = "collapse-2" >
              <ChatBox :userStrategyId="userStrategyId"></ChatBox>
                          </b-collapse>
                        </div></div></div>
        </div>
        </div>
    </div>
</template>

<script>
import _ from 'lodash'
import { mapGetters } from 'vuex'
import moment from 'moment'
import DomToImage from 'dom-to-image'
import { saveAs } from 'file-saver'
import ChatBox from '@/components/ChatBox.vue'

export default {
	name: 'graph',
	components: {ChatBox},
  data: function() {
    return {
	    isLoader: false,
    	userStrategyId : parseInt(this.$route.params.id),
	    userStrategy: ''
    }
  },
	computed: {
		series () {
			let chartSeries = []
			if(this.userStrategy) {
				chartSeries = _.map(this.userStrategy.chartData, (val, index) => {
					return {
						x: moment(val[ 'returnMonth' ], 'MM-YYYY'),
						y: val[ 'cumulativeValue' ].toFixed(0)
					}
				})
			}
			return [{
				name: 'Cumulative Portfolio',
				data: chartSeries
			}]

		},
		options () {
			return {
				plotOptions: { bar: { columnWidth: "100%" } },
				chart: {
					id: 'user-strategy-chart',
					toolbar: {
						show: true,
						tools: {
							download: true,
							selection: true,
							zoom: true,
							zoomin: true,
							zoomout: true,
							pan: true,
							// reset: true | '<img src="/static/icons/reset.png" width="20">',
							customIcons: []
						},
						autoSelected: 'zoom'
					},
				},
				xaxis: {
					tickPlacement: 'between',
					type: 'datetime',
						categories: this.returnMonths,
					labels: {
						showDuplicates: false,
							format: 'yy',
							formatter: function (val, timestamp) {
							return moment(timestamp).format('MMM-YY')
						},
						datetimeFormatter: {
							year: 'yy'
						},
						min: _.head(this.returnMonths),
						max: _.last(this.returnMonths)
					},
				},
				yaxis: {
					show: true,
					showAlways: true,
					title: {
						text: 'Cumulative Portfolio Result',
						rotate: 90,
						offsetX: 0,
						offsetY: 0,
						style: {
							color: '#00aced',
							fontSize: '15px',
							// fontFamily: 'Helvetica, Arial, sans-serif',
							// cssClass: 'apexcharts-yaxis-title',
						},
					},
				}
			}
		},
    returnMonths () {
			if(this.userStrategy) {
				return _.map(this.userStrategy.chartData, chart => {
					return moment(chart[ 'returnMonth' ], 'MM-YYYY')
				})
			}
    },
    performanceStats () {
			return this.userStrategy ? this.userStrategy.performanceStats : {}
		},
	},
	methods: {
		async fetchSetups () {
				if (! this.userStrategy) {
					await this.$store.dispatch('UserStrategy/fetch').then(res => {
						this.userStrategy = this.$store.getters['UserStrategy/find'](this.userStrategyId)
          })
				}else{
					this.userStrategy = this.$store.getters['UserStrategy/find'](this.userStrategyId)
        }
		},
		sessionValidate () {
			if(!this.$auth.isAuthenticated()){
				this.$router.push('/login')
			}
		},
		readChat () {
			if(this.userStrategy.newMessages.length > 0) {
				this.$store.dispatch('Chat/readStatus', {
					userStrategyId: this.userStrategyId,
					userId: null,
				})
			}
		},
    download() {
			let node = document.getElementById('print-image')
      let options = {
		    quality: 1.0
	    }
	    DomToImage.toBlob(node, options).then(function (blob) {
		    saveAs(blob, 'strategy-result.png')
	    })
    }
	},
  async mounted () {
	  this.sessionValidate()
    await this.fetchSetups()
	  this.readChat()
    this.isLoader = false
  }
}

</script>

<style scoped>
    .performance-stats-class{
        border-top-right-radius: 4px;
        border-top-left-radius: 4px;
        }
  /*.card{flex-direction: initial;}*/
  .mt-5em{margin-top: 5em}
  .card-custom{    box-shadow: 0px 0px 8px 0px;}
  .pb-4em{padding-bottom: 4em}
  .mt-3em-b-1-w{
    margin-top: 1em;
    border-top: 1px solid white;
    }
  .card-custom2{
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
    width: 30%;
    background-color: #2c3e50;
    border-bottom: 3px solid #00aced;

      /*margin-top: 15px;*/
    padding: 11px;
    text-align: center;
    font-weight: bold;
    }
    .perform-stats-tr{
        border-top-style: hidden;
        border-left-style: hidden;
        border-right-style: hidden;
        }
    .capture-icon{
        font-size: 20px;
        }
    .solent-watermark{
        margin-top: -6%;
        opacity: 40%;
        }
</style>
