<template >
	<div class="" >
		<div class="clearfix"></div>
		<footer class="footer  footer-custom " >
			<div class="container">
				<div class="row">
					<div class="col-md-12 mt-1em">
						<p class="text-solent mb-0 text-center" >© Solent Investments. All Rights Reserved.</p>
						<p class="text-solent text-center"><a href="http://Solentsis.com/" class="text-white text-decoration-none">Home</a></p>
					</div>
				</div>

			</div>
		</footer>

		<!-- Footer -->
	</div >
</template >
<script >
	export default {
		name: 'footer-primary',
    components: {  },
	}
</script >
<style scoped >

	.text-solent{
		font-size: 11px;
		color: white;
	}

</style >
