<template >
	<div>
						<div class = "card-body w-100 chat-row " id="chat" ref="messagesContainer">
							<div v-for = "chat in conversations" class = "rounded " style = "position: relative" >

								<i  :class = "{'text-right': !chat.isAdmin,'fas fa-headset icon-left': chat.isAdmin,'icon-right ':
							!chat.isAdmin}"> {{!chat.isAdmin ?  profile.name[0] : ''}} </i>


								<div :class = "{'text-right': !chat.isAdmin,'bg-white width-left mb-4': chat.isAdmin,'customer-chat-color text-light width-right mb-4':
							!chat.isAdmin}" >
								<span v-text = "chat.message" class = " p-2" >
							</span > <br > <small class="ml-2 mr-3" v-text = "chat.createdAtHuman" :class = "{'text-right': !chat.isAdmin,'text-dark': chat.isAdmin,'text-white': !chat.isAdmin}" ></small >
								</div >
							</div >
							<!--<br >-->
						</div >
						<div class = "row w-100 pt-3 ml-1" >
							<span class = "ml-3 mt-1" >Message:</span >
							<div class = "form-group d-flex" >
								<input type = "text" v-model = "message" class = "form-control col-sm-12 ml-3" >
								<button type = "submit" v-model = "message" @click = "send" class = "btn btn-primary ml-2 col-sm-4" >Send</button >
							</div >
						</div >
					</div>

</template >
<script >
	import {mapGetters} from 'vuex'
	import moment from 'moment'
	import _ from 'lodash'
	export default {
		props: ['userStrategyId'],
		name: 'chat-box',

		components: {},
		data () {
			return {
				message: ''
			}
		},
		updated() {
			this.$nextTick(() => this.scrollToEnd());
		},
		computed: {
			...mapGetters({
				              chats: 'Chat/get',
				              profile: 'Auth/profile',
			              }),
			conversations () {
				return _.filter(this.chats, chat => {
					return this.userStrategyId ?
					       chat.userStrategyId === this.userStrategyId && chat.isUserStrategyLinked :
					       ! chat.isUserStrategyLinked
				})
			},
		},
		methods: {
			scrollToEnd: function () {
				let content = this.$refs.messagesContainer;
				content.scrollTop = content.scrollHeight
			},
			send () {
				this.$store.dispatch('Chat/store', {
					isAdmin: false,
					message: this.message,
					userStrategyId: this.userStrategyId ? this.userStrategyId : null,
				}).then(res => {
					this.message = ''
					if (res.status === 200) {
						this.$store.dispatch('Chat/fetch')
					}
				}).catch(error => {
				})
			},
			fetchSetups () {

				if (this.chats.length === 0) {
					this.$store.dispatch('Chat/fetch')
				}
				if(_.isEmpty(this.profile)) {
					this.$store.dispatch('Auth/fetch')
				}
			},
		},
		mounted () {
			this.fetchSetups(  )
		}

	}
</script >
<style >
	.chat-row {
		background-color : #f1f1f1;
		width            : auto;
		overflow-y       : scroll;
		overflow-x       : hidden;
		max-height       : 400px;
		}
	.width-left {
		width         : 80%;
		float         : left;
		border-radius : 10px;
		padding       : 5px;
		}
	.width-right {
		width         : 80%;
		float         : right;
		border-radius : 10px;
		padding       : 5px;
		}
	.customer-chat-color {
		background-color : #5aa5ff;

		}
	.rounded{
		width: 100%;
		overflow: hidden;
		}
	.icon-left{
		float: left;
		border-radius: 50px;
		background: #F99800;
		/*padding: 12px 13px;*/
		margin-top: 5px;
		padding: 3% 3%;

		font-size: 20px;
		margin-right : 1%;
		color: white;
		}
	.icon-right{
		float: right;
		margin-left : 1%;
		border-radius: 50px;
		margin-top: 6px;
		padding: 2% 4%;
		font-size: 20px;
		font-weight: bold;
		background: #F99800;
		color: white;
		text-transform: uppercase;

		}
	.collapsed > .when-opened,
	:not(.collapsed) > .when-closed {
		display : none;
		}
</style >