import axios from 'axios'
import common from '../common'
const LOGIN_URL = 'oauth/token';
const GET_PROFILE_URL = 'profile';
const POST_GENERATE_TOKEN_URL = 'password/generate-reset-token';
const POST_PASSWORD_RESET_URL = 'password/reset';

export default {
  login: function ({ commit }, creds) {
	    let params = {
		    client_id: process.env.VUE_APP_CLIENT_ID,
		    client_secret: process.env.VUE_APP_CLIENT_SECRET,
		    grant_type: 'password',
		    username: creds.email,
		    password: creds.password,
	    }
	    return axios.post(process.env.VUE_APP_BASE_URL + LOGIN_URL , params).then(response => {
		    let data = response.data
		    let token = {
			    token: data.access_token,
			    expiredAt: JSON.stringify(
				    data.expires_in * 1000 + new Date().getTime()
			    )
		    }
		    commit('setToken', token)
		    return {code: response.status}
		  }).catch(error => {
		  	commit('removeToken')
			  return common.prepareCatch(error)
		  })
  },
	validateToken: function ({commit, state}, from ) {
			let time = new Date().getTime()
			let expiredAt = state.expiredAt | JSON.parse(state.expiredAt) | 0
			if (time > expiredAt) {
				// commit('removeToken')
				// router.push('/login')
				return false
			}
			return true
		},
	fetch: function ({ commit }) {
		return axios.get(GET_PROFILE_URL).then(res => {
			commit('setProfile', common.prepareThen(res).data)
			return Promise.resolve(common.prepareThen(res))
		}).catch(error => {
			return common.prepareCatch(error)
		})
	},
	destroy: function ({ commit }, id) {
		return axios.delete(`profile/${id}/destroy`).then(res => {
			return Promise.resolve(res)
		}).catch(error => {
			return Promise.reject(error);
		})
	},
	update: function ({ commit }, params) {
		return axios.put(`profile/${params.id}/update`, {
			name: params.name,
			phoneNo: params.phoneNo,
		}).then(res => {
			commit('setProfile', common.prepareThen(res).data)
			return Promise.resolve(common.prepareThen(res))
		}).catch(error => {
			return Promise.reject(error);
		})
	},
	createToken: function ({ commit }, params) {
		return axios.post(POST_GENERATE_TOKEN_URL, params).then(res => {
			return res
		}).catch(error => {
			return Promise.reject(error);
		})
	},
	passwordReset: function ({ commit }, params) {
		return axios.post(POST_PASSWORD_RESET_URL, params).then(res => {
			return res
		}).catch(error => {
			return Promise.reject(error);
		})
	},



}
