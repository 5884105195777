import axios from 'axios'
import common from '../common'
const GET_MARKET_RECORDS_URL = 'market-records';

export default {
    fetch: function ({ commit }, params) {
		  return axios.post(GET_MARKET_RECORDS_URL,{
		  	id: params.id,
		  }).then(res => {
			  commit('set', common.prepareThen(res).data)
		  }).catch(error => {
			  return common.prepareCatch(error)
		  })
  }
}
