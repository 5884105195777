<template>
    <div>
      <SideSubMenu page="volatility targeting"/>
<div class="content-page">
      <h4 class="top-heading mb-3">Volatility Targeting</h4>

      <p>
            Sometimes you will have a clear idea of the amount of volatility that you would like your strategy to produce.  No problem.  The <span class="welcome-internal-headings">Solent
                                                                                                                                                                                     Investment
                                                                                                                                                                                     Strategy Builder
        </span>can create a
            strategy with the particular volatility that you desire.
      </p>

      <p>
            Click on the “Create Strategy” tab and then select the “Volatility Targeting” strategy from the drop down menu.  Then choose any one of the strategies that you have already created from the “Your Strategies” drop down menu.  Choose your target volatility in the “Target Volatility %” window.  Hint: choose a level that is lower than the original strategy produced.
      </p>

      <p>
            Once you have chosen the strategy that you would like to volatility target, use the submit button at the bottom of the page and the <span class="welcome-internal-headings">Solent
                                                                                                                                                                                        Investment
                                                                                                                                                                                        Strategy
                                                                                                                                                                                        Builder</span> will create your
            volatility targeted portfolio.  You can view and download the results of this strategy.  Check the volatility of your new strategy with that of the original, it should be lower.  But
            remember lower volatility often yields a lower return.  It’s that simple to use the <span class="welcome-internal-headings">Solent Investment Strategy Builder.</span>

       </p>
        </div>
    </div>
</template>
<script >
  import SideSubMenu from '@/components/SideSubMenu.vue'
  import {Strategy} from "../enums/Strategy";

  export default {
    name: "volatility-control",
    components: {
      SideSubMenu
    },
  }
</script >

<style scoped>
  .welcome-internal-headings{
    color: #59B6DB;
    font-weight: 500;
    font-style: italic;
    }
  .content-page {
    padding : 4%;
    width: 80%;
    justify-content : center;
    text-align:justify;

    }
  .top-heading{
    margin-top: -6%;
    }
  P{
    font-size: 17px;
    }
</style>
