<template>
    <div>
        <div class="container container-custom ">
            <div class="container container-relative mb-4">
                <div class="row justify-content-center">
                    <div class="col-md-12">
                        <div class="card mt-3em-b-1-w">
                            <div class=" heading-color card-custom2">Conversation</div>

                            <div class = "row justify-content-center mt-3 mb-3" >
                                <div class = "col-sm-6">
                                    <div class = "card" >

                                        <div class = "card-header" >
                                            <h5 >Welcome to Support</h5 >
                                        </div >
                                        <ChatBox :userStrategyId="false"></ChatBox>

                                    </div>
                    </div>

                </div>
            </div>
        </div>
        </div>
    </div>
            <p class=" p-0 m-0 text-center">
                <span class="support-email"> Or email us at<a href=" mailto:support@solentsis.com" class="text-white text-decoration-none "> support@solentsis.com </a></span></p>
        </div>
    </div>
</template>

<script>
import _ from 'lodash'
import { mapGetters } from 'vuex'
import moment from 'moment'
import ChatBox from '@/components/ChatBox.vue'

export default {
	name: 'support',
	components: {ChatBox},
  data: function() {
    return {
	    isLoader: false,
    	userStrategyId : parseInt(this.$route.params.id),
    }
  },
	computed: {
		...mapGetters({
			              profile: 'Auth/profile',
			              chats: 'Chat/get',
			              newMessages: 'Chat/unReadSupportMessagesCount',
		              }),
	},
	methods: {
		async fetchSetups () {
			if(_.isEmpty(this.profile)) {
				this.$store.dispatch('Auth/fetch')
			}
		},
		sessionValidate () {
			if(!this.$auth.isAuthenticated()){
				this.$router.push('/login')
			}
		},
		readChat () {
			if(this.newMessages ) {
				this.$store.dispatch('Chat/readStatus', {
					userStrategyId: null,
					userId: this.profile.id,
        }).then(res => {
					this.$store.dispatch('Chat/fetch')
				})
			}
		}
	},
  async mounted () {
	  this.sessionValidate()
    await this.fetchSetups()
	  this.readChat()
    this.isLoader = false
  }
}

</script>

<style scoped>
    /*.card-header{*/
        /*display: none;*/
        /*}*/
  /*.card{flex-direction: initial;}*/
  .mt-5em{margin-top: 5em}
  .card-custom{    box-shadow: 0px 0px 8px 0px;}
  .pb-4em{padding-bottom: 4em}
  .mt-3em-b-1-w{
    margin-top: 1em;
    border-top: 1px solid white;
    }
  .card-custom2{
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
    width: 30%;
    background-color: #2c3e50;
    border-bottom: 3px solid #00aced;

      /*margin-top: 15px;*/
    padding: 11px;
    text-align: center;
    font-weight: bold;
    }
    .support-email{
        background-color: #F99800;
        color: white;
        padding: 4px;
        padding-right: 20px;
        padding-left: 20px;
        border-radius: 5px;
        }
</style>
