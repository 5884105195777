<template>
    <div class="container container-custom">
        <div class="container container-relative">
            <div class="row justify-content-center ">
<!--	            <button title="Capture Result" class="btn btn-primary pull-right refresh-button refresh-button-compare capture-icon " @click="download"> <i class="fas fa-camera"></i></button>-->

              <!--<div class="row">-->
                <button @click="redirect" class="btn refresh-button refresh-button-compare">Help</button >
              <!--</div>-->
	            <div class="col-md-12 padding-rightClass">
                    <div class="card mt-3em-b-1-w">
                        <div class=" heading-color col-md-6 col-sm-12 col-lg-3 col-xs-12 card-custom-create user-strategy">User Strategy</div>
                        <!--<form action="" method="post">-->
                        <div class="card-body card-body-custom">
                            <div class="row">
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label>From</label>
                                        <vue-monthly-picker v-model="selectedFromDate"
                                                            :monthLabels="monthLabels"
                                                            placeHolder="Select from date"
                                                            dateFormat="MM-YYYY"
                                                            alignment="center"
                                                            :min="minDate"
                                                            :max="maxDate"
                                                            class="create-date-picker"
                                                            :clearOption="false"
                                                            :disabled="isVolatilityStrategySelected"
                                        > </vue-monthly-picker>
<!--                                        <span class="error-message">From date is requierd</span>-->
	                                    <!--<date-pick class="form-control plaf give-border" :format="'MM-YYYY'"-->
                                                         <!--v-model="$v.fromDate.$model" :class="{ 'border-danger': ! $v.fromDate.required }"-->
                                    <!--&gt;</date-pick>-->
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
	                                    <label>To</label >
                                        <vue-monthly-picker v-model="selectedToDate"
                                                            :monthLabels="monthLabels"
                                                            placeHolder="Select to date"
                                                            dateFormat="MM-YYYY"
                                                            alignment="center"
                                                            :min="minDate"
                                                            :max="maxDate"
                                                            class="create-date-picker"
                                                            :clearOption="false"
                                                            :disabled="isVolatilityStrategySelected"
                                        > </vue-monthly-picker>
<!--                                        <span class="error-message">To date is requierd</span>-->

                                        <!--<date-pick class="form-control give-border" :format="'MM-YYYY'" v-model="$v.toDate.$model"-->
                                                       <!--:class="{ 'border-danger': ! $v.toDate.required}"></date-pick>-->
                                    </div>
                                </div>
                              <div class="col-md-4">
                                <div class="form-group select-group-custom">
                                  <label ref="currencyLabel">Select Currency</label>
                                  <!--<span class="strategy-custom">-->

                                  <v-select v-model="$v.selectedCurrency.$model" :clearable="isClearable"
                                            :class="{ 'border border-danger rounded-sm': ! $v.selectedCurrency.required }"
                                            :options="currencies" >
                                  </v-select>
                                  <span class="error-message" v-if="! $v.selectedCurrency.required">Please select currency</span>
                                </div>
                              </div>

                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group select-group-custom">
                                        <label>Select Strategy</label>
                                        <!--<span class="strategy-custom">-->

                                        <v-select v-model="$v.selectedStrategy.$model" :clearable="isClearable"
                                                  :class="{ 'border border-danger rounded-sm': ! $v.selectedStrategy.required }"
                                                  :options="strategies" >
                                          <div slot="no-options">No Strategies Available</div>
                                        </v-select>
                                        <span class="error-message" v-if="! $v.selectedStrategy.required">Please select one strategy</span>

                                        <!--:class="{ 'vs__dropdown-toggle': $v.selectedStrategy.$error }"-->
                                        <!--</span>-->
                                    </div>
                                </div>
                                <div class="col-md-6" v-if="isMomentumStrategySelected">
                                    <div class="form-group">
                                        <label>Pick Top Returns:</label>
                                        <input type = "text" class="form-control give-border"
                                               v-model="$v.strategyParams.momentum.pickTopValue.$model"
                                               :class="{ 'border-danger': $v.strategyParams.momentum.pickTopValue.$error }"
                                        >
                                        <span class="error-message" v-if="$v.strategyParams.momentum.pickTopValue.$error">Pick Top Returns Required</span>

                                        <!--<v-select v-model="selectedStrategy" :options="strategies"></v-select>-->
                                    </div>
                                </div>
                                <div class="col-md-6" v-if="isTFOrEWStrategySelected">
                                    <div class="form-group weight-choice">
                                      <label>Weight Choice</label>
	                                    <v-select ref="weightChoice"  :options="trendFollowingChoices"  :clearable="isClearable"
                                                v-model="$v.selectedTFOrEWChoice.$model"
                                                :class="{ 'border-danger': $v.selectedTFOrEWChoice.$error}"
                                      >
                                        <div slot="no-options">No Strategies Available</div>
                                      </v-select>
                                        <span class="error-message" v-if="! $v.selectedTFOrEWChoice.required">Please choose weight</span>

                                    </div>
                                </div>
                                <div class="col-md-6" v-if="isVolatilityStrategySelected">
                                    <div class="form-group weight-choice">
                                      <label>Your strategies</label>
	                                    <v-select   :options="userStrategies" :clearable="isClearable"
                                                v-model="$v.selectedVolatilityChoice.$model"
                                                :class="{ 'border-danger': ! $v.selectedVolatilityChoice.required}"
                                      >
                                        <div slot="no-options">No Strategies Available</div>
                                      </v-select>
                                        <span class="error-message" v-if="! $v.selectedVolatilityChoice.required">One strategy must be selected</span>

                                    </div>
                                    <div class="form-group">
                                      <label>Target Volatility %</label>
                                        <input type = "text" class="form-control give-border"
                                               v-model="$v.strategyParams.volatilityTriggering.targetVolume.$model"
                                               :class="{ 'border-danger': ! $v.strategyParams.volatilityTriggering.targetVolume.required }"
                                        >
                                        <span class="error-message" v-if="! $v.strategyParams.volatilityTriggering.targetVolume.required ">Target Volume required</span>

                                    </div>
                                </div>
                            </div>
                            <div class="row" v-if="isShowMarketsTable">
                                <div class="col-6">
                                    <table class=" table mt-5  table-striped table-striped-create ">
                                        <thead>
                                        <tr class="rowheadcolor">
                                            <th class="w-50 heading-color first-table">
                                                <span class="float-left">Available Markets</span>
                                                <span class="float-right length-color">{{availableMarkets.length}}</span>
                                            </th>
                                        </tr>
                                        </thead>
                                        <tbody v-show="availableMarkets">

                                            <tr v-for="market in availableMarkets">
                                            <td class="">
                                                <span class="float-left" v-if="market">{{market.broadCategory}} - {{market.name}}</span>
                                                <!--<a class="btn  float-right heading-color" @click="removeAvailable(market.id)"><span style=''>&#8594;</span></a>-->
                                                <!--                                                <a class="float-right arrow-custom " @click="removeAvailable(market.id)">&#8680;</a>-->
                                                <a class="float-right  " @click="removeAvailable(market.id)">
                                                    <!--                                                    <i class="fa fa-hand-o-right" aria-hidden="true"></i>-->
                                                    <i class="fa fa-plus text-white-50"  aria-hidden="true"></i>

                                                </a>
                                            </td>
                                        </tr>

                                        </tbody>
                                    </table>
                                </div>
                                <div class="col-6 ">
<!--                                    <span class="error-message position-absolute" v-if="!$v.totalTFOrEWPercentage.between">Please select Available Markets</span>-->
	                                <span class="error-message following-percentage position-absolute" v-if=" !$v.selectedMarkets.between">
		                                Your selected markets range should be between {{$v.selectedMarkets.$params.between.min}} and
		                                {{$v.selectedMarkets.$params.between.max}}
	                                </span>

                                        <span v-if="isTFOrEWStrategySelected ">
                                          <span class="error-message following-percentage position-absolute"
                                               v-if="!$v.totalTFOrEWPercentage.between &&
                                               isTFOrEWWithCustomSelected && $v.selectedMarkets.between">
	                                         Percentage should be 100%
                                          </span>
                                        </span>
                                    <table class=" table table-striped-create mt-5 ">
                                        <thead>
                                            <tr class="rowheadcolor">
                                            <th class="w-50 heading-color selected-market-heading pr-2">

                                                <div>
                                                    <span class="float-left" v-text="selectedMarketsListLabel"></span>
                                                    <span class="float-right " v-if="isTFOrEWStrategySelected"
                                                          :class="{ 'text-danger': !$v.totalTFOrEWPercentage.between }"
                                                    >{{totalTFOrEWPercentage}}%
                                                        <i class="fas fa-info-circle color-white "
                                                           title="Accumulative weighted for all selected markets must be 100%">
                                                        </i>
                                                    </span>
                                                    <span class="float-right "
                                                          :class="{ 'text-danger mr-2': !$v.selectedMarkets.between }"
                                                    >{{selectedMarkets.length}}
                                                        <i class="fas fa-info-circle color-white"
                                                           :title="'Selected markets should be between ' + totalSelectedMarketsCountValidation.min + ' and ' + totalSelectedMarketsCountValidation.max">
                                                        </i>
                                                    </span>
                                                </div>
                                            </th>
                                        </tr>
                                        </thead>
                                        <tbody v-show="selectedMarkets">
<!--                                            <span class="error-message" v-if="!$v.totalTFOrEWPercentage.between">Please select between total following percentage</span>-->

                                            <tr v-for="market in selectedMarkets">
                                            <td>
	                                            <div class="row">
                                                <div class="col-sm-12 " style="display:contents ">
                                                    <div :class="{ 'col-sm-7':  isTFOrEWWithCustomSelected,
                                                                   'col-sm-10':  !isTFOrEWWithCustomSelected, }">
                                                        <span class="float-left ">
                                                            {{market.broadCategory}} - {{market.name}}
                                                        </span>
                                                    </div>
                                                    <label class="" v-if="strategyParams.trendFollowing.choice === tfChoiceEqual ||
                                                                          strategyParams.equalWeight.choice === tfChoiceCustom ||
                                                                          strategyParams.flexibleWeight.choice === tfChoiceCustom"></label>
                                                    <div class="col-sm-3" v-if="isTFOrEWWithCustomSelected">
                                                    <input type = "text" class="form-control text-center give-border"
                                                           :class="{ 'border-danger': !$v.totalTFOrEWPercentage.between }"
                                                           v-if="strategyParams.trendFollowing.choice === tfChoiceCustom ||
                                                                 strategyParams.equalWeight.choice === tfChoiceCustom ||
                                                                 strategyParams.flexibleWeight.choice === tfChoiceCustom"
                                                           v-model="market.TFOrEWPercentage">

                                                    </div>

                                                    <div class="col-sm-2 float-right" >  <a class="float-right left-arrow" @click="removeSelected(market.id)">
                                                        <i class="fa fa-minus text-white-50 float-right" aria-hidden="true"></i>
                                                    </a>
                                                    </div>
                                                </div>
                                              </div>
                                            </td>
                                        </tr>

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div class="row justify-content-center ">
                            <b-button @click="check()"  class="save-button-custom col-sm-3 text-center" >Submit</b-button>
                        </div>
                        </div>
                        <b-modal ref="modal-1"  id="modal-1" title="Your Strategy Name" hide-footer>
                            <div>
	                            <form action = "" @submit.prevent="store">
                                <b-form-input placeholder="Enter your strategy name" v-model="$v.name.$model"
                                              :class="{ 'border-danger': $v.name.$error }" >
                                </b-form-input>
                                  <span class="error-message" v-if="$v.name.$error">Please enter your strategy name</span>

                                  <br>
                                <button class="btn form-control float-lg-right modal-save-btn col-2"  v-if="isShow" @click="store" > Save </button>
                                <circle2  v-if="!isShow" class="solent-spinner float-lg-right col-2 mr-3"></circle2>
                              </form>
                            </div>
                        </b-modal>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import VueMonthlyPicker from 'vue-monthly-picker'
import { mapGetters } from 'vuex'
import  moment  from 'moment'
import DatePick from 'vue-date-pick'
import { required, requiredIf, between, helpers } from 'vuelidate/lib/validators'
import {Strategy} from "../../enums/Strategy";
import {TFWeightChoice} from "../../enums/TFWeightChoice";

export default {
	name: 'user-strategy-create',
	components: {
		DatePick,
		VueMonthlyPicker
	},
	data () {
		return {
      isClearable: false,
			showMarkets: [ ],
      name: '',
      tfChoiceEqual: TFWeightChoice.EQUAL_VALUE,
      tfChoiceCustom: TFWeightChoice.CUSTOM_VALUE,
      isShow: true,
      selectedFromDate: moment('2000-03'),
      selectedToDate: moment('2005-12'),
      selectedStrategy: '',
      selectedCurrency: {},
			currencies: [
				{value: 'USD', label: 'USD'},
				{value: 'GBP', label: 'GBP'}
			],
      selectedTFOrEWChoice: {},
      selectedVolatilityChoice: {},
			strategyParams: {
      	momentum: {pickTopValue: 0},
        trendFollowing: {choice: ''},
        equalWeight: {choice: ''},
        flexibleWeight: {choice: ''},
				volatilityTriggering: {strategy: '', targetVolume: 0}},
      totalPercentage: 0,
      monthLabels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
		}
	},
	validations: {
		name: {required},
		fromDate: {required,
      between (value) {
				let minVal = moment('01-01-2000')
				let maxVal = moment('01-01-2019')
				return between(minVal, maxVal)(value)
			}},
		toDate: {required},
		selectedStrategy: {required},
		selectedCurrency: {required},
		strategyParams: {
			momentum: {
				pickTopValue:{
					required: requiredIf(function(params) {
					  return this.isPropertyAvailable(Strategy.MOMENTUM)
				  }),
          between (value) {
						let maxLength = this.selectedMarkets.length - 1
						return between(1, maxLength)(value)
          }
				}
      },
			volatilityTriggering: {
				targetVolume:{
					required: requiredIf(function(params) {
					  return this.isPropertyAvailable(Strategy.VOL_TARGET_FILTER)
				  }),
					between (value) {
						return between(1, 100)(value)
					}
				}
      },
    },
		selectedTFOrEWChoice: {required},
		selectedVolatilityChoice: {required},
		totalTFOrEWPercentage: {between (value) {
				return between(100, 100)(parseFloat(value))
			}
		},
    selectedMarkets: {between (value) {
				return between(this.totalSelectedMarketsCountValidation.min,
                       this.totalSelectedMarketsCountValidation.max)(value.length)
			},
		}
	},
	computed: {
		...mapGetters({
			              cashReturns: 'cashReturn/get',
			              strategies: 'strategy/lists',
			              markets: 'market/get',
			              userStrategies: 'UserStrategy/listsExceptVT',
			              allUserStrategies: 'UserStrategy/get',
			              validationProperties: 'UserStrategy/getValidationProperties',
		              }),
    strategy () {
			if(_.has(this.selectedStrategy, 'value')) {
				return this.$store.getters[ 'strategy/find' ](this.selectedStrategy.value)
			}
			return {}
    },
		trendFollowingChoices () {
			if(this.selectedStrategy.label.toLowerCase() === Strategy.FLEXIBLE_WEIGHTS_YOU_CHOOSE) {
				return [
					{value: TFWeightChoice.CUSTOM_VALUE, label: TFWeightChoice.CUSTOM_LABEL}
				]
			} else {
				return [
					{value: TFWeightChoice.EQUAL_VALUE, label: TFWeightChoice.EQUAL_LABEL},
					{value: TFWeightChoice.CUSTOM_VALUE, label: TFWeightChoice.CUSTOM_LABEL}
				]
      }
    },
    fromDate () {
			if(this.selectedFromDate !== null) {
				return this.selectedFromDate.format('MM-YYYY')
			}
			return ''
    },
    toDate () {
			if(this.selectedToDate !== '') {
				return this.selectedToDate.format('MM-YYYY')
			}
			return ''
    },
    minDate () {
			return moment(this.validationProperties.minDate)
    },
    maxDate () {
			return moment(this.validationProperties.maxDate)
    },
    strategyIsValidated () {
			let cumulative = this.$v.toDate.required &&
                       this.$v.fromDate.required &&
                       this.$v.selectedStrategy.required &&
                       this.$v.selectedCurrency.required
      let strategySpecific = false
	    if(! _.isEmpty(this.selectedStrategy)) {
		    switch (this.selectedStrategy.label.toLowerCase()) {
			    case Strategy.TREND_FOLLOWING:
				    strategySpecific = ! this.$v.selectedTFOrEWChoice.$error &&
				                       this.$v.selectedMarkets.between
           break
           case Strategy.EQUAL_WEIGHT:
				    strategySpecific = ! this.$v.selectedTFOrEWChoice.$error &&
				                       this.$v.totalTFOrEWPercentage.between &&
				                       this.$v.selectedMarkets.between
           break
			    case Strategy.MOMENTUM:
           strategySpecific = ! this.$v.strategyParams.momentum.pickTopValue.$error &&
                              this.$v.selectedMarkets.between
           // strategySpecific = this.$v.strategyParams.momentum.pickTopValue.required &&
           //                    this.$v.strategyParams.momentum.pickTopValue.between &&
           //                    this.$v.selectedMarkets.between
				   break
			    case Strategy.VOL_TARGET_FILTER:
           strategySpecific = this.$v.selectedVolatilityChoice.required &&
                              this.$v.strategyParams.volatilityTriggering.targetVolume.required
				   break
          // case Strategy.RISK_PARITY:
          //  strategySpecific = this.$v.selectedVolatilityChoice.required &&
          //                     this.$v.strategyParams.volatilityTriggering.targetVolume.required
				   // break
                    case Strategy.FLEXIBLE_WEIGHTS_YOU_CHOOSE:
           strategySpecific =  this.$v.totalTFOrEWPercentage.between &&
                                               this.isTFOrEWWithCustomSelected && this.$v.selectedMarkets.between
				   break
          default:
          	strategySpecific = this.$v.selectedMarkets.between
		    }
	    }
	    return strategySpecific && cumulative
    },
    isShowMarketsTable () {
        return this.selectedStrategy !== '' &&
               this.selectedStrategy.label.toLowerCase() !== Strategy.VOL_TARGET_FILTER
    },
    volatilityTriggeringSelectedStrategy () {
			if(! _.isEmpty(this.selectedVolatilityChoice)) {
				return _.chain(this.allUserStrategies).filter(strategy => {
					return strategy.id === this.selectedVolatilityChoice.value
				}).head().value()
			}
			// else{
			// 	return false
			// }
    },
    totalSelectedMarketsCountValidation () {
			if(! _.isEmpty(this.selectedStrategy)) {
				switch (this.selectedStrategy.label.toLowerCase()) {
					case Strategy.MOMENTUM:
						return {min: 2, max: this.markets.length}
          default:
	          return {min: this.strategy.minMarkets, max: this.strategy.maxMarkets}
				}
			}
			return {min: 0, max: 0}
    },
		totalTFOrEWPercentage () {
			let totalPercentage = 0
			if (!_.isEmpty(this.selectedStrategy) &&
          this.selectedTFOrEWChoice.value === TFWeightChoice.CUSTOM_VALUE) {
				_.each(this.showMarkets, market => {
					totalPercentage = totalPercentage + parseFloat(market.TFOrEWPercentage)
				})
			}else if(!_.isEmpty(this.selectedStrategy) &&
			         this.selectedTFOrEWChoice.value === TFWeightChoice.EQUAL_VALUE){
				totalPercentage = 100
      }
			return totalPercentage ? totalPercentage.toFixed(0) : 0
		},
		selectedMarkets () {
			return _.filter(this.showMarkets, market => {
				return market.isSelected
			})
		},
		selectedMarketsListLabel () {
			if (!_.isEmpty(this.selectedStrategy)) {
				switch (this.selectedStrategy.label.toLowerCase()) {
					case Strategy.TREND_FOLLOWING:
						return 'Selected Markets & Weight'
					default:
						return 'Selected Markets'
				}
			}
		},
		isMomentumStrategySelected () {
			return this.isPropertyAvailable(Strategy.MOMENTUM)
		},
		isTFOrEWStrategySelected () {
			return this.isPropertyAvailable(Strategy.TREND_FOLLOWING) ||
             this.isPropertyAvailable(Strategy.EQUAL_WEIGHT) ||
             this.isPropertyAvailable(Strategy.FLEXIBLE_WEIGHTS_YOU_CHOOSE)
		},
    isTFOrEWWithCustomSelected () {
			console.log('FW, ', this.strategyParams.equalWeight.choice)
			return (this.isPropertyAvailable(Strategy.TREND_FOLLOWING) ||
             this.isPropertyAvailable(Strategy.EQUAL_WEIGHT) ||
             this.isPropertyAvailable(Strategy.FLEXIBLE_WEIGHTS_YOU_CHOOSE)) &&
             ((this.strategyParams.trendFollowing.choice === this.tfChoiceCustom) ||
              (this.strategyParams.equalWeight.choice === this.tfChoiceCustom) ||
              (this.strategyParams.flexibleWeight.choice === this.tfChoiceCustom))
		},
		isVolatilityStrategySelected () {
			return this.isPropertyAvailable(Strategy.VOL_TARGET_FILTER)
		},
		selectedMarketIds () {
			return _.chain(this.selectedMarkets).map(market => {
				return [ market.id ]
			}).flatten().value()
		},
		selectedMarketTFPercentage () {
		if(this.selectedStrategy.label.toLowerCase() === Strategy.TREND_FOLLOWING ) {
			return _.chain(this.selectedMarkets).map(market => {
				return [ parseFloat(market.TFOrEWPercentage) ]
			}).flatten().value()
		}
		return []
		},
		selectedMarketEWPercentage () {
		if(this.selectedStrategy.label.toLowerCase() === Strategy.EQUAL_WEIGHT ||
       this.selectedStrategy.label.toLowerCase() === Strategy.FLEXIBLE_WEIGHTS_YOU_CHOOSE) {
			return _.chain(this.selectedMarkets).map(market => {
				return [ parseFloat(market.TFOrEWPercentage) ]
			}).flatten().value()
		}
		return []
		},
		availableMarkets () {
			return _.filter(this.showMarkets, market => {
				return ! market.isSelected
			})
		},
	},
	watch: {
		selectedTFOrEWChoice () {
			_.each(this.showMarkets, market => {
				market.TFOrEWPercentage = 0
			})
			if(this.selectedStrategy.label.toLowerCase() === Strategy.TREND_FOLLOWING) {
				this.strategyParams.trendFollowing.choice = this.selectedTFOrEWChoice.value
			}else if(this.selectedStrategy.label.toLowerCase() === Strategy.EQUAL_WEIGHT ){
				this.strategyParams.equalWeight.choice = this.selectedTFOrEWChoice.value
      }
    },
		volatilityTriggeringSelectedStrategy () {
			this.selectedFromDate = moment(this.volatilityTriggeringSelectedStrategy.fromDateFormatted, 'MM-YYYY')
			this.selectedToDate = moment(this.volatilityTriggeringSelectedStrategy.toDateFormatted, 'MM-YYYY')
    },
		selectedVolatilityChoice () {
			this.strategyParams.volatilityTriggering.strategy = this.selectedVolatilityChoice.value
    },
		selectedStrategy () {
			_.each(this.showMarkets, market => {
				market.isSelected = false
        market.TFOrEWPercentage = 0
			})
      if(this.selectedStrategy.label.toLowerCase() === Strategy.FLEXIBLE_WEIGHTS_YOU_CHOOSE){
				this.selectedTFOrEWChoice = {value: TFWeightChoice.CUSTOM_VALUE, label: TFWeightChoice.CUSTOM_LABEL}
	      this.strategyParams.flexibleWeight.choice = this.tfChoiceCustom
      }else{
	      this.selectedTFOrEWChoice = {}
      }
    },
	},
	methods: {
        check(){
            if(this.strategyIsValidated){
                this.$refs['modal-1'].show()
            }else{
                this.$refs.currencyLabel.scrollIntoView({ behavior: 'smooth' });
            }
        },
		isPropertyAvailable(strategy) {
			return this.selectedStrategy !== null && !_.isEmpty(this.selectedStrategy)
        ? this.selectedStrategy.label.toLowerCase() === strategy : false
		},
		prepareListOfMarkets () {
			this.showMarkets = _.map(this.markets, market => {
				return {
					id: market.id,
					name: market.name,
					broadCategory: market.broadCategory.name,
					isSelected: false,
          TFOrEWPercentage: 0
				}
			})
		},
		removeAvailable (id) {
			_.forEach(this.showMarkets, market => {
				if (market.id === id) {
					market.isSelected = true
				}
			})
		},
		removeSelected (id) {
			_.forEach(this.showMarkets, market => {
				if (market.id === id) {
					market.isSelected = false
					market.TFOrEWPercentage = 0
				}
			})
		},
		fetchSetups () {
			if(this.strategies.length === 0) {
				this.$store.dispatch('strategy/fetch')
			}
			if(this.userStrategies.length === 0) {
				this.$store.dispatch('UserStrategy/fetch')
			}
			if(this.cashReturns.length === 0) {
				this.$store.dispatch('cashReturn/fetch')
			}
			if(_.isEmpty(this.validationProperties)) {
				this.$store.dispatch('UserStrategy/fetchValidationProperties')
			}
			if(this.markets.length === 0) {
				this.$store.dispatch('market/fetch').then(res => {
					this.prepareListOfMarkets()
				})
			}else{
				this.prepareListOfMarkets()
			}
		},
    store () {
			if(this.strategyIsValidated && this.$v.name.required) {
				this.isShow = false
				this.$store.dispatch('UserStrategy/store', {
					name: this.name,
					strategyParams: this.strategyParams,
					fromDate: '01-' + this.fromDate,
					toDate: '01-' + this.toDate,
					strategyId: this.selectedStrategy.value,
					currency: this.selectedCurrency.value,
					selectedMarketIds: this.selectedMarketIds,
					selectedMarketTFPercentage: this.selectedMarketTFPercentage,
					selectedMarketEWPercentage: this.selectedMarketEWPercentage,
				}).then(res => {
          if(res.status === 200) {
	          this.$store.dispatch('UserStrategy/fetch').then(res => {
		          this.$toastr.s('Strategy building, will be available shortly.', 'Success')
		          this.$router.push('/user-strategies')
	          })
          }else if(res.status === 401) {
	          this.$router.push('/logout')
          }else {
	          this.isShow = true
	          this.$toastr.e('Something went wrong please try again.', 'Error')
          }
				}).catch(err => {
					this.isShow = true
          if(err.response.status === 422){
						_.forEach(err.response.data.message, error => {
							this.$toastr.e(error, 'Error')
            })
          }else {
	          this.$toastr.e('Something went wrong please try again.', 'Error')
          }
        })
			}
            else if(!this.$v.name.required){
                this.$v.name.$touch()
            }
    },
		sessionValidate () {
			if(!this.$auth.isAuthenticated()){
				this.$router.push('/login')
			}
		},
		redirect () {
			this.$store.commit('SiteParams/set', 'help')
			this.$router.push('/equal-weight')
			}
	},
	mounted () {
		this.sessionValidate()
		this.fetchSetups()
	}
}
</script>
<style scoped>
    .border-danger {
        border-color: #dc3545!important;
        }
	@media screen and (max-width: 636px){
		.refresh-button{
			margin-right: 2%;
			}
		}
	@media screen and (max-width: 506px){
		.refresh-button{
			margin-right: 4%;
			}
		}
</style >
<style >


    .mt-3em-b-1-w{
        margin-top: 1em;
        border-top: 1px solid white;
    }
    .save-button-custom{
        /*width: 20%;*/
        border-radius: 25px;
        padding: 10px;

        margin: 0 auto;
        margin-bottom: 2%;
    }
    tr td .vdpCell.selected .vdpCellContent {
        color: #fff;
        background: #59B6DB;
    }
    .save-button-custom:hover{
        /*color: black !important; background:  #28b6ec*/
        background-color: #FC9401;
        color : white;
        }
    .first-table{
        background-color: #59B6DB;
        }
    .selected-market-heading{
        background-color: #59B6DB;
        }
    .pb-15{padding-bottom: 15px}
    .card-body-custom .row div .form-group .vdpComponent{height: 39px; }
    .card-body-custom .row div .form-group .vdpComponent input{ background-color: #E8F2F4 !important;}
    .select-group-custom .v-select .vs__dropdown-toggle{    height: 39px;}
    .arrow-custom{
        padding: 0px;
        line-height: 14px;
        font-size: 42px;
        cursor: pointer !important;
    }
    .container-relative{
        position: relative;
    }
    @media screen and(max-width:390px){
        .container-relative{
            padding: 0;
            }
        }
    .container-custom{
        /*height: 100vh;*/
        /*margin-bottom: 8em;*/
        /*padding-bottom: 8em;*/
        /*min-height: 500px;*/
        box-shadow: 0px 0px 8px 0px;
        margin-bottom: 2%;
    }
    .refresh-button{
        position: absolute;
        top: 1.5em;
        right: 36px;
        z-index: 99;
    }
    .card-custom-create{
        border-top-right-radius: 25px;
        border-bottom-right-radius: 25px;
        /*width: 30%;*/
        /*margin-top: 15px;*/
        padding: 11px;
        text-align: center;
        font-weight: bold;
    }
    @media screen and (max-width: 480px) {
	    .container-custom .container .justify-content-center .mt-3em-b-1-w .heading-color  {
		    width : 60% !important;
		    }
	    }
    @media screen and (max-width: 767px){
        .first-table{
            padding-bottom: 28%;

            }
        }
    @media (max-width: 470px) and (min-width:415px){
        .first-table{
            padding-bottom: 21%;

            }
        }
    .left-arrow{
        /*margin-left: 25%;*/
        }
    .v-select {
        background-color: #E8F2F4;
        border-bottom: 3px solid #FC9400;
        border-radius: 0;
        }
    .v-select .vs__dropdown-toggle{
      border: 0;
        }
    .give-border {
        background-color: #E8F2F4;
        border-bottom: 3px solid #FC9400 !important;
        border-radius: 0;
        border: 0;
        }
    .modal-save-btn{
        background-color: #FC9400;
        color: white;
        }
    .modal-save-btn:hover{
        background-color: #FC9400;
        color: white;
        }
    .length-color{
        /*background-color: #132E3F;*/
        }
    .table-striped-create>tbody>tr {
        background-color: #193E51;
        color :#28b6ec;
        border-top : 0 ;

        }
    .table-striped-create>tbody>tr:nth-child(odd)>td {
        background-color : #132e3f;
        color            : #28b6ec;
        border-top       : 0;
        }
    .table-striped-create>tbody>tr:nth-child(even)>td {
        border-top       : 0;
        color            : #28b6ec;

        }
    .user-strategy{
        background-color : #132e3f;
        border-bottom: 3px solid #28b6ec;
        }

    @media screen and (max-width: 512px){
        .container-custom{
                padding: 0;
            }
        .card-body-custom{
            padding: 3%;
            }
        .save-button-custom{
            width: 50%;
            }
        }
    @media screen and (max-width: 348px){
        .padding-rightClass{
            padding-right: 0;
            padding-bottom: 0!important;
            }
        .container-custom{
            padding-bottom: 0!important;
            }
        .mt-3em-b-1-w{
            margin-top: 0;
            }
        .vdpComponent.vdpWithInput>input {
            /* padding-right: 30px; */
            padding-right: 0;
            }
        }
    .create-date-picker .month-picker-wrapper .month-year-label .month-year-display{
        border: none;
        border-bottom: 3px solid #FC9400 !important;
        background: #E8F2F4;
        border-radius: 0;
        height: 39px;
        border-top: 0 !important;
        -webkit-box-shadow:none;
        }
    .error-message{
        color: #DC3545;
        }
	.following-percentage{
		margin-top: 4%;
		}
</style>

