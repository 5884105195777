<template>
    <div>
      <SideSubMenu page="equal weight"/>
<div class="content-page">
      <h4 class="top-heading mb-4">Equal Weight</h4>
                <p class="strategy-content"><span class="welcome-internal-headings">Equal Weight </span> is often a very appealing investment strategy. Click on the “Create Strategy” tab and then
                                                                                     select “Equal Weight” from the drop down
                                                         menu. Choose the sample period that you are interested in.  Then choose the markets that you are interested in gaining access to from the
                                                         “Available Markets” drop down menu.</p>
                                     <p>Once you have chosen all of the markets that you would like to include in your strategy, use the submit
                                                         button at the bottom of the page; the
                                       <span class="welcome-internal-headings">Solent Investment Strategy Builder</span> will create a portfolio where each of these markets is weighted equally.  Each
                                                                               month the market weights
                                        are recalculated to maintain the equally-weighted exposure to each market.  You can view and download the results of this strategy.  It’s that simple to use
                                        the <span class="welcome-internal-headings">Solent Investment Strategy Builder.</span></p>

      </div>
    </div>
</template>
<script >
  import SideSubMenu from '@/components/SideSubMenu.vue'
  import {Strategy} from "../enums/Strategy";

  export default {
    name: "equal-weight",
    components: {
      SideSubMenu
    },
  }</script >


<style scoped>
  .content-page {
    padding : 4%;
    width: 80%;
    justify-content : center;
    text-align:justify;

    }
	.top-heading{
		margin-top: -6%;
		}
	P{
		font-size: 17px;
		}
  .welcome-internal-headings{
    color: #59B6DB;
    font-weight: 500;
    font-style: italic;
    }
</style>
