import _ from 'lodash'

export default {
  get: state => {
    return state.markets
  },
  lists: state => {
    return _.map(state.markets, market => {
        return {
          value: market.id,
          label: market.name

        }
    })
  },
}
