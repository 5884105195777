<template >


	<table class="wrapper" width="100%" cellpadding="0" cellspacing="0" style="font-family: Avenir, Helvetica, sans-serif; box-sizing: border-box; background-color: #f5f8fa; margin: 0; padding: 0; width: 100%; -premailer-cellpadding: 0; -premailer-cellspacing: 0; -premailer-width: 100%;">
		<tr>
			<td align="center" style="font-family: Avenir, Helvetica, sans-serif; box-sizing: border-box;">
				<table class="content" width="100%" cellpadding="0" cellspacing="0" style="font-family: Avenir, Helvetica, sans-serif; box-sizing: border-box; margin: 0; padding: 0; width: 100%; -premailer-cellpadding: 0; -premailer-cellspacing: 0; -premailer-width: 100%;">
					<tr>
						<td class="header" style="font-family: Avenir, Helvetica, sans-serif; box-sizing: border-box;background: #f37461; padding: 6px 0; text-align: center;">
							<img style="height: 47px;top: 3px;position: relative;" src="https://www.solentsis.net/wp-content/themes/solentsis/images/logo.png" alt="Solent">
						</td>
					</tr>

					<!-- Email Body -->
					<tr>
						<td class="body" width="100%" cellpadding="0" cellspacing="0" style="font-family: Avenir, Helvetica, sans-serif; box-sizing: border-box; margin: 0; padding: 0; width: 100%; -premailer-cellpadding: 0; -premailer-cellspacing: 0; -premailer-width: 100%;">
							<table class="inner-body" align="center" width="570" cellpadding="0" cellspacing="0" style="font-family: Avenir, Helvetica, sans-serif; box-sizing: border-box; background-color: #FFFFFF; margin: 0 auto; padding: 0; width: 570px; -premailer-cellpadding: 0; -premailer-cellspacing: 0; -premailer-width: 570px;">
								<!-- Body content -->
								<tr>
									<td class="content-cell" style="font-family: Avenir, Helvetica, sans-serif; box-sizing: border-box; padding: 35px;">
										<h1 style="font-family: Avenir, Helvetica, sans-serif;    margin-bottom: 8px; box-sizing: border-box; color: #000000;  font-size: 19px; font-weight: bold; margin-top: 0; text-align: left;">
											Hi Shafqat Ali,
										</h1>
										<p style="font-family: Avenir, Helvetica, sans-serif;  margin-top: 0px ; margin-bottom: 8px; box-sizing:  border-box; color: black; font-size: 15px; line-height: 2.5em; text-align: left;">
											<!--Thanks for signing up to Solent!--><br/>
											<span style="top: 8px; position: relative;">
                                            Save time and effort online, on your terms.
                      </span>
										</p>
										<p style="font-family: Avenir, Helvetica, sans-serif;font-size: 15px;color: black;margin-top: 0px">
<!--											Really appreciate having you on board.-->
										</p>

										<div class="table-class">

											<b-table striped hover :items="items"></b-table>

<!--											<h1 style="font-family: Avenir, Helvetica, sans-serif; box-sizing: border-box; color: black; font-size: 19px; font-weight: bold; margin-top: 0; text-align: left;">-->
<!--											What's next?-->
<!--										</h1>-->
<!--										<ul>-->
<!--											<li>-->
<!--												<p style="font-family: Avenir, Helvetica, sans-serif;font-size: 15px;color: black;">-->
<!--													&lt;!&ndash;Add your preferences on <b><a style="font-size: 18px;text-decoration: underline" href="https://uat.Solent.io">My Solent</a></b>, and partner sites become more-->
<!--													precise. My Solent is also where you can review and edit all your saved-->
<!--													preferences.&ndash;&gt;-->
<!--													Stay tuned! <b> <span style="">We’re launching Solent on first retail sites very soon.</span> </b>-->

<!--												</p>-->
<!--											</li>-->
<!--											<li>-->
<!--												<p style="font-family: Avenir, Helvetica, sans-serif;font-size: 15px;color: black;">-->
<!--													&lt;!&ndash;  Try Solent on our <b><a style="font-size: 18px;text-decoration: underline"-->
<!--																										 href="https://uat.Solent.io/participating">partner sites</a></b>.-->
<!--														These sites are personalised based on your-->
<!--														preferences.&ndash;&gt;-->
<!--													<a style="font-size: 15px;color: black;text-decoration: underline" href="mailto:hello@Solent.io">-->
<!--														Let us know </a> Which sites you would like to get personalised with Solent.-->

<!--												</p>-->
<!--											</li>-->
<!--											<li>-->
<!--												<p style="font-family: Avenir, Helvetica, sans-serif;font-size: 15px;color: black;">-->
<!--													&lt;!&ndash; Check out Solent in your browser bar.<img style="height: 30px;" src="https://uat.Solent.io/images/logo_transparent.png" alt="">  It’s a shortcut to see e.g. which-->
<!--													 sites are personalised and with what information.&ndash;&gt;-->
<!--													Follow us on <a style="font-size: 15px;color: black;text-decoration: underline" href="https://twitter.com/SolentExp">-->
<!--													Twitter</a>,<a style="font-size: 15px;color: black;text-decoration: underline" href="https://www.instagram.com/Solentexp/"> Instagram </a> and-->
<!--													<a style="font-size: 15px;color: black;text-decoration: underline" href="https://www.linkedin.com/company/Solent/">LinkedIn </a> for the very latest.-->

<!--												</p>-->
<!--											</li>-->
<!--										</ul>-->
<!--										<p style="font-family: Avenir, Helvetica, sans-serif;font-size: 15px;color: black;">-->
<!--											More Solent news to come soon.-->

<!--										</p>-->

										<table class="subcopy" width="100%" cellpadding="0" cellspacing="0" style="font-family: Avenir, Helvetica, sans-serif; box-sizing: border-box;">
											<tr>
												<td style="font-family: Avenir, Helvetica, sans-serif; box-sizing: border-box;">
													<p style="font-family: Avenir, Helvetica, sans-serif; box-sizing: border-box;color: black;
                                                 line-height: 1.5em; margin-top: 0; text-align: left; font-size: 15px;">
														Any questions or comments? We’d love to hear from you. <a style="font-size: 16px;text-decoration: underline"
														                                                          href="mailto:solent@Solent.io">Contact us</a>.
													</p>
												</td>
											</tr>
										</table>

										</div>
									</td>
								</tr>
							</table>
						</td>
					</tr>
					<tr></tr>
					<tr></tr>
					<tr>
						<td align="center" style="    top: 20px;position: relative;">
							<table width="375" cellpadding="0" cellspacing="0" border="0" align="center">
								<tbody>
									<tr>
										<td align="center" width="80" style="padding-top: 2em;">
											<a href="https://web.facebook.com/SolentExp/?_rdc=1&_rdr" style="text-decoration:none" target="_blank" ><img src="https://ci5.googleusercontent.com/proxy/9BhOWR4E1eBhixZzGL9Qd_nwuA8z4GUVLmCrTd46Bx7qhovrn6z20GX1Io6WN1MYhTt9dJYoyTF_dWHZrhaxZXHEzL9uDPeFZhbCbZz92zxjj54QGJTvyx0u=s0-d-e1-ft#http://image.send.grammarly.com/lib/fe9213707567007a7d/m/1/Facebook.png" border="0" width="12" height="21" style="width:12px;height:21px;max-width:21px;border:none;outline:none;text-decoration:none;font-size:19px;font-family:Helvetica Neue,Arial,sans-serif;color:#3b5998;font-weight:bold" alt="  f" class="CToWUd"></a>
										</td>
										<td align="center" width="80" style="padding-top: 2em;">
											<a href="https://www.instagram.com/Solentexp/" style="text-decoration:none" target="_blank"><img src="https://ci5.googleusercontent.com/proxy/FpQWpXfv9nzk5JDl9lvuI-5EdUnmFy1iIfZuXHaFwEf9geFhVWrGP6DjBzWnK9fONxAWXxQgEZ5ocaeLaCbljorz30ePfX0R1iFgDwWGzDtAP30s4HIbZQNXKQ=s0-d-e1-ft#http://image.send.grammarly.com/lib/fe9213707567007a7d/m/1/Instagram.png" border="0" width="26" height="25" style="width:26px;height:25px;max-width:26px;border:none;outline:none;text-decoration:none;font-size:19px;font-family:Helvetica Neue,Arial,sans-serif;color:#00aced;font-weight:bold" alt="   i" class="CToWUd"></a>
										</td>
										<td align="center" width="80" style="padding-top: 2em;">
											<a href="https://www.linkedin.com/company/Solent/" style="text-decoration:none" target="_blank" ><img src="https://ci6.googleusercontent.com/proxy/6cAaPVJIahnVE88qajAeCfASTnFRFYCahDIboxyOnDby2zhJ1mfbsIpH1BzNjJWQYmGoZnCdjk9nfVjBaFzawmRJFUNHr9Hdfe-dTAgHIHcXEafTYqNs1BOB=s0-d-e1-ft#http://image.send.grammarly.com/lib/fe9213707567007a7d/m/1/LinkedIn.png" border="0" width="19" height="18" style="width:19px;height:18px;max-width:19px;border:none;outline:none;text-decoration:none;font-size:19px;font-family:Helvetica Neue,Arial,sans-serif;color:#007bb6;font-weight:bold" alt=" in" class="CToWUd"></a>
										</td>
										<td align="center" width="80" style="padding-top: 2em;">
											<a href="https://twitter.com/SolentExp" style="text-decoration:none" target="_blank" ><img src="https://ci4.googleusercontent.com/proxy/Wz4zQ0-Yil7Qkcc8AosJ2Xo_QB6D2IYVF9MgYblVGHUBodshIgBoHk5ciOUhW472GTVLUv1AoS1KmC-jkY0IQ5YTRZfjEN8CK9QpxzOD2T5dH8q5drrroLs=s0-d-e1-ft#http://image.send.grammarly.com/lib/fe9213707567007a7d/m/1/Twitter.png" border="0" width="23" height="20" style="width:23px;height:20px;max-width:23px;border:none;outline:none;text-decoration:none;font-size:19px;font-family:Helvetica Neue,Arial,sans-serif;color:#00aced;font-weight:bold" alt="  t" class="CToWUd"></a>
										</td>
									</tr>

								</tbody>

							</table>
							<table style="width: 570px;margin-top: 12px;">
								<tr>
									<hr style="border: 1px solid gainsboro;"/>
								</tr>
							</table>
						</td>
					</tr>

					<tr></tr>

					<tr>
						<td style="font-family: Avenir, Helvetica, sans-serif; box-sizing: border-box;">
							<table class="footer" align="center" width="570" cellpadding="0" cellspacing="0" style="font-family: Avenir, Helvetica, sans-serif; box-sizing: border-box; margin: 0 auto; padding: 0; text-align: center; width: 570px; -premailer-cellpadding: 0; -premailer-cellspacing: 0; -premailer-width: 570px;">
								<tr>
									<td class="content-cell" align="center" style="font-family: Avenir, Helvetica, sans-serif; box-sizing: border-box;    padding: 22px 0px 0px 0px;">
										<p style="font-family: Avenir, Helvetica, sans-serif; box-sizing: border-box; line-height: 1.5em; margin-top: 0; color: #AEAEAE; font-size: 12px; text-align: center;">
											You received this email because you are registered on Solent.io with the <br/> email address abc@gmail.com
										</p>
									</td>
								</tr>
							</table>
						</td>
					</tr>
					<tr>
						<td style="font-family: Avenir, Helvetica, sans-serif; box-sizing: border-box;">
							<table class="footer" align="center" width="570" cellpadding="0" cellspacing="0" style="font-family: Avenir, Helvetica, sans-serif; box-sizing: border-box; margin: 0 auto; padding: 0; text-align: center; width: 570px; -premailer-cellpadding: 0; -premailer-cellspacing: 0; -premailer-width: 570px;">
								<tr>
									<td class="content-cell" align="center" style="font-family: Avenir, Helvetica, sans-serif; box-sizing: border-box;">
										<p style="font-family: Avenir, Helvetica, sans-serif; box-sizing: border-box; line-height: 1.5em; margin-top: 0; color: #AEAEAE; font-size: 12px; text-align: center;">© 2019 Solent Ltd, 80-83 Long Lane, London EC1A 9ET, UK</p>
									</td>
								</tr>
							</table>
						</td>
					</tr>
				</table>
			</td>
		</tr>
	</table>

</template >
<style scoped>

	@media  only screen and (max-width: 600px) {
		.inner-body {
			width: 100% !important;
			}

		.footer {
			width: 100% !important;
			}
		}

	@media  only screen and (max-width: 500px) {
		.button {
			width: 100% !important;
			}
		}
</style >
<script>
	export default {
		data() {
			return {
				items: [
					{ UserStrategyName: 'test', Strategy: 'Dickerson', Parameters: 'Macdonald' , FromMonth: '01-01-2019', ToMonth:'02-10-2020',TotalMarkets:'21'},

				]
			}
		}
	}


</script><style scoped >
</style >