import _ from 'lodash'

export default {
  get: state => {
    return state.cashReturns
  },
  avg: state => {
    let sum = _.sumBy(state.cashReturns, (cashReturn) => {
      return cashReturn.returnPercentage
    })
    return (sum/state.cashReturns.length) * 100
  },
  lists: state => {
    return _.map(state.cashReturns, cashReturn => {
        return {
          value: cashReturn.id,
          label: cashReturn.name
        }
    })
  },
  // dateRanges: state => {
  //   return {
      // minDate: _.min(state.cashReturns.returnMonth),
      // maxDate: _.max(state.cashReturns.returnMonth),
    // }
}
