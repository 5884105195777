import _ from 'lodash'

export default {
	get: state => {
		return state.conversations
	},
	unReadSupportMessagesCount: state => {
		return _.chain(state.conversations)
			.filter(conversation => {
				return !conversation.isUserStrategyLinked &&
               ! conversation.isUserRead
			}).size()
	}
}
