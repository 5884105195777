<template >
	<div class="col-md-8 mt-3em pull-right pb-10">
        <!--<div class="topnav" id="myTopnav">-->
			<!--<div class="user-strategies pull-right " style="text-align: right">-->
				<!--<router-link to = "/login" class = "pl-2 pr-2" >Login</router-link > |
				<router-link to = "/register" class = "pl-2 pr-2" >Register</router-link >-->

				<div class="login-item">
                    <router-link to = "/login" class = "item-menu-link hovering-colr  text-decoration-none" > Login</router-link >

                    <!--<li class="item-menu">-->
                    <router-link to = "/register" class = "item-menu-link-register hovering-colr  text-decoration-none" > Register</router-link >
                </div>

	</div>

        <!--</div>-->
</template >
<script >
	import {mapGetters} from 'vuex'
	export default {
		name: 'top-menu-bar-guest',
		data () {
			return {}
		},
		methods: {

		}
	}
</script >
<style scoped >
	.item-menu{width: 15%;float: right}
	.hovering-colr:hover{color: #59B6DB}
	.nav {
		padding         : 30px;
		justify-content : center;
		}
	.nav a {
		font-weight : bold;
		color       : #2c3e50;
		}
	.nav a.router-link-exact-active {
		color : #42b983;
		}
</style >
