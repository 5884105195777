import axios from 'axios'
import common from '../common'
const GET_CONVERSATIONS_URL = 'conversations'
const STORE_CONVERSATIONS_URL = 'conversations/store'
const READ_STATUS_URL = 'conversations/read-status'

export default {
    fetch: function ({ commit }) {
		  return axios.get(GET_CONVERSATIONS_URL).then(res => {
			  commit('set', common.prepareThen(res).data)
		  }).catch(error => {
			  return common.prepareCatch(error)
		  })
  },
	store: function ({ commit }, data) {
		return axios.post(STORE_CONVERSATIONS_URL, data).then(res => {
			return res
		}).catch(error => {
			return common.prepareCatch(error)
		})
	},
	readStatus: function ({ commit }, data) {
		return axios.post(READ_STATUS_URL, data).then(res => {
			return res
		}).catch(error => {
			return common.prepareCatch(error)
		})
	},

}
