import Vue from 'vue'
import VueToastr from 'vue-toastr'
import "vue-toastr/src/vue-toastr.scss";

Vue.use(VueToastr, {
	'defaultTimeout': 4000,
	'defaultProgressBar': true,
	'defaultProgressBarValue': 0,
	'defaultType': 'info',
	'defaultPosition': 'toast-top-right',
	'defaultCloseOnHover': false,
	'defaultClassNames': {'animated': 'zoomInUp'},
})