<template >
	<div>
		<AuthLayout v-if="isAuth"/>
		<GuestLayout v-if="!isAuth"/>
	</div>
</template >
<script >
	import AuthLayout from '@/components/AuthLayout.vue'
	import GuestLayout from '@/components/GuestLayout.vue'
	export default {
		name: "layout",
		props: ['isAuth'],
		components: {
			AuthLayout,
			GuestLayout
		},
	}
</script >
<style scoped >
</style >