import Vue from 'vue'
import Vuex from 'vuex'
import { strategy } from './strategy/store'
import { market } from './market/store'
import { cashReturn } from './cashReturn/store'
// import { broadCategory } from './broadCategory/store'
import { marketRecord } from './marketRecord/store'
import { UserStrategy } from './userStrategy/store'
import { Auth } from './auth/store'
import { Chat } from './chat/store'
import { SiteParams } from './siteParams/store'

Vue.use(Vuex)

export default new Vuex.Store({
	                              modules: {
		                              strategy,
		                              market,
		                              cashReturn,
		                              // broadCategory,
		                              marketRecord,
		                              UserStrategy,
		                              Auth,
		                              Chat,
		                              SiteParams,
	                              }
                              })
