export default {
  set (state, isAuthenticated) {
    state.isAuthenticated = isAuthenticated
  },
  setProfile (state, profile) {
    state.user = profile
  },
  setToken (state, params) {
		  let expiredAt = JSON.stringify(
			  (params.expiredAt * 1000) + new Date().getTime()
		  )
		  localStorage.setItem(process.env.VUE_APP_LOCAL_STORAGE_TOKEN_LABEL, params.token)
		  localStorage.setItem(process.env.VUE_APP_LOCAL_STORAGE_EXPIRED_AT_LABEL, expiredAt)
    state.isAuthenticated = true
    state.expiredAt = expiredAt
  },
  removeToken (state) {
	  localStorage.removeItem(process.env.VUE_APP_LOCAL_STORAGE_TOKEN_LABEL)
	  localStorage.removeItem(process.env.VUE_APP_LOCAL_STORAGE_EXPIRED_AT_LABEL)
	  state.isAuthenticated = false
	  state.expiredAt = 0
  },
}
