import _ from 'lodash'
import {Strategy} from "../../enums/Strategy";

export default {
  get: state => {
    return state.userStrategies
  },
	getValidationProperties: state => {
    return state.validationProperties
  },
	getComparisonIds: state => {
    return state.comparisonIds
  },
	allChartGenerated: state => {
		let isPending = _.filter(state.userStrategies, strategy => {
			return ! strategy.isChartGenerated
		})
		return isPending.length ? false : true
	},
	validForComparison: state => {
		return _.filter(state.userStrategies, strategy => {
			return strategy.isChartGenerated &&
			       strategy.isFileGenerated
		})
	},
	allFileGenerated: state => {
		let isPending = _.filter(state.userStrategies, strategy => {
			return !strategy.isFileGenerated
		})
		return isPending.length ? false : true
	},
	find: state => {
		return id => {
			return _.find(state.userStrategies, userStrategy => {
				return parseInt(userStrategy.id) === parseInt(id)
			})
		}
	},
	lists: state => {
		return _.chain(state.userStrategies)
		        .map(strategy => {
							return {
								value: strategy.id,
								label: strategy.strategy.name + ': ' +  strategy.name
							}
						}).value()
	},
	listsExceptVT: state => {
		return _.chain(state.userStrategies)
		        .filter(strategy => {
		        	return strategy.strategy.name.toLowerCase() !== Strategy.VOL_TARGET_FILTER &&
			               strategy.isFileGenerated && strategy.isChartGenerated &&
			               strategy.isValidForVolatilityTargeting

		        })
		        .map(strategy => {
							return {
								value: strategy.id,
								label: strategy.strategy.name + ': ' +  strategy.name
							}
						}).value()
	},
	comparisonSelected: state => {
		return _.chain(state.userStrategies)
		        .filter(strategy => {
		        	return _.includes(state.comparisonIds, strategy.id)
		        }).value()
	},
}
