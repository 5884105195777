import getters from './getters'
import mutations from './mutations'

export const SiteParams = {
  namespaced: true,
  state: {
    welcomePageFrom: 'welcome'
  },
  getters,
  mutations,
};

