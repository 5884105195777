<template >
	<div>

		<SideSubMenu page="welcome"/>
		<div class="content-page">
		<h4 class = "top-heading mb-4" ></h4 >
		<!--<h4 class = "top-heading mb-4" >Welcome</h4 >-->
		<p class = "strategy-content" >
			<span class="welcome-internal-headings">Welcome to the Solent Investment Strategy Builder.</span>
			  Using this unique tool you will be able to create your own rules-based, asset allocation strategies and analyse their historic performance.
		</p >
		<p >
			The <span class="welcome-internal-headings">Strategy Builder</span> gives you the opportunity to implement an <span class="welcome-internal-headings"> Equal Weight </span> strategy; a strategy based upon
			                                                                                                                                                 the principles of <span
			class="welcome-internal-headings"> Risk Parity</span>; a strategy
			that
			harnesses	the
			benefits	of <span class="welcome-internal-headings"> Trend
			                                                   Following</span>; and a strategy based upon the principles of <span class="welcome-internal-headings">Momentum </span>investing.
			The building blocks are a range of broad asset classes. Choose from Developed and Emerging market equities, a range of fixed income markets, commodities and REITs to populate your strategy.
		</p>
		<p>
			Then when you have created your strategy, if you would like to lower the volatility of your chosen strategy you can overlay a volatility target using a simple Volatility Targeting filter.
			Enjoy the Strategy Builder which demonstrates that asset allocation can be implemented using academically-evidenced rules.
			We look forward to hearing back from you.
		</p >
	</div >
	</div>
</template >

<script >
	import SideSubMenu from '@/components/SideSubMenu.vue'
	import {Strategy} from "../enums/Strategy";
	import { mapGetters } from 'vuex'

	export default {
		name: "welcome",
		computed: {
			strategyView() {
				// switch(this.page){
				//   case Strategy.EQUAL_WEIGHT:
				//   	return EqualWeight
				//   case Strategy.RISK_PARITY:
				//   	return RiskParity
				//   case Strategy.TREND_FOLLOWING:
				//   	return TrendFollowing
				//   case Strategy.MOMENTUM:
				//   	return Momentum
				//   case Strategy.VOL_TARGET_FILTER:
				//   	return VolatilityControl
				//   default:
				//     if(this.page === 'help') {
				//       this.view = Strategy.EQUAL_WEIGHT
				//       return EqualWeight
				//     }
				//     this.view = 'welcome'
				//     return Welcome
				// }
			}
		},
		components: {
			SideSubMenu
		},
    mounted() {
	    this.$store.commit('SiteParams/set', 'welcome')
    }
	}
</script >

<style scoped >
	.content-page {
		padding : 4%;
		width: 80%;
		justify-content : center;
		text-align:justify;

		}
	.top-heading {
		margin-top : -6%;
		}
	P {
		font-size : 17px;
		}
	.welcome-internal-headings{
		color: #59B6DB;
		font-weight: 500;
		font-style: italic;
		}
</style >
