<template>
    <div>
      <SideSubMenu page="momentum"/>
<div class="content-page">
      <h4 class="top-heading mb-3">Momentum</h4>

          <p>
            The <span class="welcome-internal-headings">Solent Investment Strategy Builder</span> can help you create portfolios based upon the principle of Momentum investing.  This strategy
                                                        involves: looking back over a pre-specified
            period; identifying the best performers over this period; and then investing in a fixed number of these top performers each month.  The process essentially involves “buying past winners” and has a long academic pedigree as an investment strategy.
          </p>

          <p>
            Click on the “Create Strategy” tab and then select the “Momentum” strategy from the drop down menu.  Choose the sample period that you are interested in.  Then choose the markets that you are interested in gaining access to from the “Available Markets” drop down menu.  Hint: it is best to choose a minimum of ten diverse markets.  Then in the “Pick Top Returns” tab, choose the number of “winning” markets that you wish to include in your portfolio.  For example, do you want to include the top 3, 4 or 5 markets in your portfolio?  You decide.  Hint: choosing fewer than 5 could leave your portfolio poorly diversified.
          </p>
          <p>
            Once you have chosen all of the markets that you would like to include in your strategy, use the submit button at the bottom of the page and the Solent Investment Strategy Builder
            will create your portfolio.  You can view and download the results of this strategy.  It’s that simple to use the <span class="welcome-internal-headings">Solent Investment Strategy
                                                                                                                                                                      Builder.</span>
          </p>
        </div>
    </div>
</template>

<script >
  import SideSubMenu from '@/components/SideSubMenu.vue'
  import {Strategy} from "../enums/Strategy";

  export default {
    name: "momentum",
    components: {
      SideSubMenu
    },
  }
</script >

<style scoped>
  .welcome-internal-headings{
    color: #59B6DB;
    font-weight: 500;
    font-style: italic;
    }
  .content-page {
    padding : 4%;
    width: 80%;
    justify-content : center;
    text-align:justify;

    }
  .top-heading{
    margin-top: -6%;
    }
  P{
    font-size: 17px;
    }
</style>