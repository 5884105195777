<template>
    <div>
      <SideSubMenu page="risk parity"/>
<div class="content-page">
      <h4 class="top-heading mb-3">Risk Parity</h4>

          <p><span class="welcome-internal-headings">Risk Parity</span> is an investment strategy that helps investors control the risk in their portfolios.  This strategy helps investors create
                                                     multi-asset class portfolios where the risks,
             represented by each asset class’s volatility, are intended to be equal.  When using this strategy capital is allocated in such a way that each asset class adds the same amount of volatility, or risk to the portfolio over time.  So an asset class with low return volatility will have a higher weight in the strategy than one with high return volatility.
          </p>
          <p>
             The Table below shows the impact that the Risk Parity algorithm has on the weights of five, broad asset classes.  The top row presents the annualised volatility of each market.  The second row presents the Risk Parity weight of each market.  We can see from the table that the Property market has the highest volatility, 23.0%, and therefore has the lowest allocation, 12.0%; while the Bonds exposure has the lowest volatility, 7.8% and has the highest weight, 36%.  The final row shows the when we multiply each market’s volatility with its weight, the risk parity-weighted volatility is the same in each case
          </p>
          <div class="table">
            <table class="table table-stripe">
              <tr>
                <th></th>
                <th>Development Equity</th>
                <th>Emerging Equity</th>
                <th style="margin-bottom: 0px">Bonds</th>
                <th>Commodities</th>
                <th>Property</th>
              </tr>
              <tr>
                <td>Volatility(V)</td>
                <td>14.1%</td>
                <td>21.1%</td>
                <td>7.8%</td>
                <td>15.5%</td>
                <td>23.0%</td>
              </tr>
              <tr>
                <td>Risk-Parity Weight(RPW)</td>
                <td>20.0%</td>
                <td>13.3%</td>
                <td>36.3%</td>
                <td>18.1%</td>
                <td>12.3%</td>
              </tr>
              <tr>
                <td>RPW x V</td>
                <td>2.82%</td>
                <td>2.82%</td>
                <td>2.82%</td>
                <td>2.82%</td>
                <td>2.82%</td>
              </tr>
            </table>
            <hr class="table-hr">

          </div>
          <p>Risk Parity is an investment strategy that helps investors control the risk in their portfolios.  This strategy helps investors create multi-asset class portfolios where the risks, represented by each asset class’s volatility, are intended to be equal.  When using this strategy capital is allocated in such a way that each asset class adds the same amount of volatility, or risk to the portfolio over time.  So an asset class with low return volatility will have a higher weight in the strategy than one with high return volatility.
          </p>
           <p> The Table below shows the impact that the Risk Parity algorithm has on the weights of five, broad asset classes.  The top row presents the annualised volatility of each market.  The
            second row presents the Risk Parity weight of each market.  We can see from the table that the Property market has the highest volatility, 23.0%, and therefore has the lowest allocation, 12.0%; while the Bonds exposure has the lowest volatility, 7.8% and has the highest weight, 36%.  The final row shows the when we multiply each market’s volatility with its weight, the risk parity-weighted volatility is the same in each case
          </p>
        </div>
    </div>
</template>

<script >
  import SideSubMenu from '@/components/SideSubMenu.vue'
  import {Strategy} from "../enums/Strategy";

  export default {
    name: "risk-parity",
    components: {
      SideSubMenu
    },
  }
</script >

<style scoped>
  .welcome-internal-headings{
    color: #59B6DB;
    font-weight: 500;
    font-style: italic;
    }
  .content-page {
    padding : 4%;
    width: 80%;
    justify-content : center;
    text-align:justify;

    }
  .top-heading{
    margin-top: -6%;
    }
  P{
    font-size: 17px;
    }
  .table-hr{
    margin-top : -2%;
    }
</style>

