import axios from 'axios'
import common from '../common'
const USER_STRATEGIES_URL = 'user-strategies'
const STORE_USER_STRATEGIES_URL = 'user-strategies/store'
const VALIDATION_PROPERTIES_URL = 'user-strategies/validation-properties'
const DOWNLOAD_URL = 'download/'

export default {
	fetch: function ({ commit }) {
		return axios.get(USER_STRATEGIES_URL).then(res => {
			commit('set', common.prepareThen(res).data)
		}).catch(error => {
			return common.prepareCatch(error)
		})
	},
	store: function ({ commit }, data) {
		return axios.post(STORE_USER_STRATEGIES_URL, data).then(res => {
			return res
		}).catch(error => {
			return Promise.reject(error);
			// return error
			// return common.prepareCatch(error)
		})
	},
	download: function ({getters}, id) {
		let strategy = getters.find(id)
		return axios.get(DOWNLOAD_URL + id, {responseType: 'blob'}).then(res => {
			const url = window.URL.createObjectURL(new Blob([res.data]))
			const link = document.createElement('a')
			link.href = url
			link.setAttribute('download', strategy.name + '.xlsx')
			document.body.appendChild(link)
			link.click()
		}).catch(error => {
			return common.prepareCatch(error)
		})
	},
	fetchValidationProperties: function ({ commit }) {
		return axios.get(VALIDATION_PROPERTIES_URL).then(res => {
			commit('setValidationProperties', common.prepareThen(res).data)
		}).catch(error => {
			return Promise.reject(error);
		})
	},
	destroy: function ({ commit }, id) {
		return axios.delete(USER_STRATEGIES_URL + `/${id}/destroy`).then(res => {
			return res
		}).catch(error => {
			return Promise.reject(error);
			// return error
			// return common.prepareCatch(error)
		})
	},

}
