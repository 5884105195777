<template>
<div>
  <div class="row main-row">

		<SideBar></SideBar>

    <div class="content-width ">
      <h1 class="dashboard-heading">{{headingTwo}}</h1>
     <div class="mt-3 ">
        <hr style="border: 1px solid black">
    </div>
	    <slot/>
    </div>
  </div>

	  <div class="login-bottom-class">
			  <p class="pt-2 mb-0">@ Solent Investments. All Rights Reserved</p>
			  <p class=" mb-1 pb-0"><a href="http://Solentsis.com/" class="text-white text-decoration-none mr-1">Home </a> | <a href="https://www.solentsis.net/contact/"
			                                                                                                                                          target="_blank"
			                                                                                                                                                class="ml-1 text-white text-decoration-none">
				  Contact Us</a></p>
    </div>
</div>
</template>

<script>
import SideBar from '@/components/SideBar.vue'
import router from '../router'

export default {
	name: 'layout',
	// props: [ 'heading' ],
	components: {SideBar},
	data: function() {
		return {
			// heading: ''
		}
	},
	computed: {
		headingTwo() {
			return this.$route.meta.heading
		}
	},
	methods: {
		// getHeading() {
		// 	router.beforeEach((to, from, next) => {
		// 		this.heading = _.head(to.matched).meta.heading
		// 		next()
		// 	})
		// }
	},
	mounted () {
		// this.getHeading()
	},
}
</script>

<style scoped>
	.login-bottom-class{
		background-color: #132E3F;
		border-top: 5px solid #FE9400;
		justify-content : center;
		text-align: center;
		color: white;
		font-size: 12px;
		padding-bottom :7px;
		padding-top : 6px;
		}
	.bottom-samrtideologi{
		text-decoration: none;
		}
	.main-row{
		width : 100%  ;
		}
	.sidebar-class{
		background-color: #132e41;
		height: 625px;
		border-right: 8px solid #59B6DB;
		padding-right: 0;
		}
	.dashboard-heading{
		padding-top: 10px;
		padding-bottom: 8px;
		background-color: #132e41;
		color: #ffffff;
		font-family: Verdana;
		font-weight: 400;
		text-align: center;
		justify-content: center;
		/*font-weight: bold;*/
		margin-right: 5% ;
		margin-left: 4% ;
		border-bottom: 7px solid #59B6DB;
		border-radius: 0px 0px 10px 10px ;
		}
	/*.nav-link{*/
	/*	color : #ffffff;*/
	/*	width: 100%;*/
	/*	}*/
	/*.nav-item{*/
	/*	margin-left: 4%;*/
	/*	margin-bottom: 3%;*/
	/*	}*/
	/*.nav-item:hover{*/
	/*	background-color: #59B6DB;*/
	/*	}*/
	/*.nav-link:hover{*/
	/*	color: black;*/
	/*	}*/
	/*.user-open{*/
	/*	display: none;*/
	/*	}*/
	/*a.menu_links { cursor: pointer; }*/
	/*.user-open{*/
	/*	display: none;*/
	/*	}*/
	/*a.menu_links { cursor: pointer; }*/
	/*.dash-cards{*/
	/*	background-color: #EEF6F9 !important;*/
	/*	}*/
	/*.sidebar-icon{*/
	/*	margin-right: 0.8rem;*/
	/*	font-size: 1.4rem;*/
	/*	}*/
	/*.content-width{*/
	/*	width : 80%;*/
	/*	margin-left: 2%;*/
	/*	margin-right: 1%;*/
	/*	}*/
	/*.sidebar-width{*/
	/*	width : 17%;*/

	/*	}*/
	/*@media screen and (max-width: 1115px) {*/
	/*	.content-width{*/
	/*		width : 77%;*/
	/*		margin-left: 2%;*/
	/*		margin-right: 1%;*/
	/*		}*/
	/*	.sidebar-width{*/
	/*		width : 20%;*/

	/*		}*/
	/*	}*/
	/*@media screen and (max-width: 992px) {*/
	/*	.content-width{*/
	/*		width : 73%;*/
	/*		margin-left: 2%;*/
	/*		margin-right: 1%;*/
	/*		}*/
	/*	.sidebar-width{*/
	/*		width : 23%;*/

	/*		}*/
	/*	}  @media screen and (max-width: 822px) {*/
	/*	.content-width{*/
	/*		width : 65%;*/
	/*		margin-left: 2%;*/
	/*		margin-right: 1%;*/
	/*		}*/
	/*	.sidebar-width{*/
	/*		width : 30%;*/

	/*		}*/
	/*	}*/
	/*@media screen and (max-width: 640px) {*/
	/*	.sidebar-icon{*/
	/*		font-size: medium;*/
	/*		}*/
	/*	.content-width{*/
	/*		width : 62%;*/
	/*		!*margin-left: 2%;*!*/
	/*		!*margin-right: 1%;*!*/
	/*		}*/
	/*	.sidebar-width{*/
	/*		width : 33%;*/

	/*		}*/
	/*	}*/
	/*@media screen and (max-width: 560px) {*/
	/*	.sidebar-icon{*/
	/*		font-size: small;*/
	/*		}*/
	/*	.nav-item{*/
	/*		font-size: 90%*/
	/*		}*/
	/*	!*.content-width{*!*/
	/*	!*width : 60%;*!*/
	/*	!*!*margin-left: 2%;*!*!*/
	/*	!*!*margin-right: 1%;*!*!*/
	/*	!*}*!*/
	/*	!*.sidebar-width{*!*/
	/*	!*width : 35%;*!*/

	/*	!*}*!*/
	/*	}*/
	/*@media screen and (max-width: 522px) {*/
	/*	.sidebar-icon{*/
	/*		font-size: small;*/
	/*		}*/

	/*	.content-width{*/
	/*		width : 60%;*/
	/*		!*margin-left: 2%;*!*/
	/*		!*margin-right: 1%;*!*/
	/*		}*/
	/*	.sidebar-width{*/
	/*		width : 35%;*/

	/*		}*/
	/*	}  @media screen and (max-width: 488px) {*/

	/*	.content-width{*/
	/*		width : 57%;*/
	/*		!*margin-left: 2%;*!*/
	/*		!*margin-right: 1%;*!*/
	/*		}*/
	/*	.sidebar-width{*/
	/*		width : 36%;*/

	/*		}*/
	/*	}*/
	/*@media screen and (max-width: 474px) {*/
	/*	.content-width{*/
	/*		width : 52%;*/
	/*		}*/
	/*	.sidebar-width{*/
	/*		width : 43%;*/

	/*		}*/
	/*	}*/
	@media screen and (max-width: 425px) {

		.dashboard-heading{
			font-size: 30px !important;
			}
		}

	@media screen and (max-width: 322px) {
		.dashboard-heading{
			font-size: 25px !important;
			}

		}
	@media screen and (max-width: 265px) {
		.dashboard-heading{
			font-size: 20px !important;
			}

		}

</style>

